import moment from "moment"
import _ from "lodash"
import memoizeOne from "memoize-one"
import { createSelector } from "reselect"
import { getResourceData } from "../core/fetcher"
import { MEASURES } from "../constants"
import { itemsToObject, matchesSearch, mergeObjects } from "../utils"
import {
    getInputDateName,
    getMeasureInputName,
    getSubmeasureInputName,
    getTreeCategoryKey,
    getTreeSubcategoryKey
} from "./helpers"
import * as C from "./constants"

// simple selectors

const getCategories = state =>
    getResourceData(C.ALL_MEASURES_TREE, data => data.categories)(state)

export const getRelevantCategories = state =>
    getResourceData(C.RELEVANT_MEASURES_TREE, data => data.categories)(state)
// can't put the "|| []" in these functions because it'll be a new empty list each time, which will prevent the selector from memoizing the value.

export const getAddMeasureFilter = state =>
    (state[C.ADD_NEW_MEASURES].filter || "").toLowerCase()

// memoized selectors

export const measuresMapSelector = createSelector(
    getResourceData(MEASURES, data => data.measures),
    measures => _.keyBy(measures, C.TABLE_KEYS.MEASURE_ID)
)
export const measureAbbrevMapSelector = createSelector(
    getResourceData(MEASURES, data => data.measures),
    measures => _.keyBy(measures, C.TABLE_KEYS.ABBREV)
)
export const submeasureAbbrevMapSelector = memoizeOne(submeasures =>
    _.keyBy(submeasures, C.TABLE_KEYS.ABBREV)
)

export const relevantCategoriesSelector = createSelector(
    getRelevantCategories,
    measuresMapSelector,
    (categories, measuresMap) =>
        (categories || []).map(category => ({
            ...category,
            subCategories: category.subCategories.map(subcategory => ({
                ...subcategory,
                measures: subcategory.measures.map(measure => ({
                    ...measure,
                    link: _.get(measuresMap, [measure.id, C.TABLE_KEYS.LINK])
                })) // the function doesn't return the link, so we gotta add it ourselves
            })),
            incentivized: _.sumBy(category.subCategories, subcategory =>
                _.sumBy(
                    subcategory.measures,
                    measure =>
                        +(
                            moment().isAfter(moment.utc(measure.nextDueDt)) ||
                            measure.compliant === C.COMPLIANCE.NOT_COMPLIANT
                        ) // unary "+" turns booleans into ints
                )
            )
        }))
)

export const inputLookupSelector = createSelector(
    getRelevantCategories,
    measuresMapSelector,
    (categories, measuresMap) =>
        mergeObjects(
            _.flatMap(categories, category =>
                _.flatMap(category.subCategories, subcategory =>
                    _.flatMap(subcategory.measures, measure => ({
                        ..._.keyBy(
                            measure.subMeasures,
                            getSubmeasureInputName(measure)
                        ),
                        [getMeasureInputName(measure)]: {
                            ...measuresMap[measure.id],
                            ...measure,
                            normalDropListOptions: measure.dropListOptions,
                            dropListOptions: itemsToObject(
                                measure.dropListOptions,
                                option => option.id,
                                option => option.name
                            )
                        },
                        [getInputDateName(
                            measure.abbrevName
                        )]: `${measure.name}'s date`
                    }))
                )
            )
        )
)

// note that this doesn't include any submeasures. Use submeasureAbbrevMapSelector above
const mapAndFilterChildren = childrenTransformer => items =>
    (items || [])
        .map(item => ({
            ...item,
            children: childrenTransformer(item.children)
        }))
        .filter(item => item.children.length > 0)

export const addMeasureSelector = createSelector(
    inputLookupSelector,
    getCategories,
    (inputLookup, categories) =>
        mapAndFilterChildren(
            mapAndFilterChildren(children =>
                children.filter(
                    measure => !inputLookup[getMeasureInputName(measure)]
                )
            )
        )(categories || [])
)

export const filteredMeasures = createSelector(
    addMeasureSelector,
    getAddMeasureFilter,
    (categories, filter) =>
        mapAndFilterChildren(
            mapAndFilterChildren(children =>
                children.filter(item => matchesSearch(item.label, filter))
            )
        )(categories)
)

export const expandedMeasureSelector = createSelector(
    filteredMeasures,
    categories =>
        _.flatMap(categories, (category, i) => [
            getTreeCategoryKey(i),
            ...category.children.map((child, j) => getTreeSubcategoryKey(i, j))
        ])
)
