import React, { Component } from "react"
import _ from "lodash"
import { itemsToObject, joinNonNil } from "../../../../../utils"
import { getMeasureDisplay, joinSubmeasureValues } from "../../../../transforms"
import * as C from "../../../../constants"
import DisplayRow from "./DisplayRow"
import EditRow from "./EditRow"

// FIXME whole lot of direct references to fields in here instead of reference via constants

const getError = (value, dataType, minValue, maxValue, name) => {
    switch (dataType) {
        case C.NUMERIC:
        case C.ALPHANUMERIC:
            if (!value && value !== 0) {
                return `${name} Value is required`
            }
            if (_.isNaN(Number(value))) {
                return `${name} Not a number`
            }
            if (!_.isNil(maxValue) && value > maxValue) {
                return `${name} Less than ${maxValue}`
            }
            if (!_.isNil(minValue) && value < minValue) {
                return `${name} Greater than ${minValue}`
            }
            break
        default:
            return null
    }
} // TODO this doesn't account for how systolic BP must be bigger than diastolic
//    can we maybe just use the actual validators instead? however those are determined

const getMeasureValue = measure =>
    measure.dataType === C.ENUMERATION
        ? measure.codeDescId
        : measure.numericVal || measure.textVal

export default class HistoricalItem extends Component {
    state = {
        editing: false,
        saving: false,
        values: {
            // unsaved values
            // XXX we probably should be using abbrevs instead of IDs, but this is pretty temporary so I think it's ok for now
            [this.props.measureId]: getMeasureValue(this.props),
            ...(this.props.dataType === C.COMPOSITE &&
                itemsToObject(
                    this.props.subMeasureValues,
                    item => item.measureId,
                    getMeasureValue
                ))
        }
    }

    onChange = value =>
        this.setState({
            values: {
                ...this.state.values,
                [this.props.measureId]: value
            }
        })
    onCompositeChange = (value, measureId) =>
        this.setState(
            {
                values: {
                    ...this.state.values,
                    [measureId]: value
                }
            },
            () => {
                this.setState({
                    values: {
                        ...this.state.values,
                        [this.props.measureId]: joinSubmeasureValues(
                            this.props.subMeasureValues,
                            getMeasureDisplay(
                                this.state.values,
                                C.TABLE_KEYS.MEASURE_ID
                            ),
                            this.props.unitLabel
                        )
                    }
                })
            }
        )
    edit = () => {
        this.setState({
            editing: true
        })
    }
    cancel = () => {
        this.setState({ editing: false })
    }
    delete = () => {
        this.setState({ saving: true })
        this.props.removeHistoricalItem({
            measureValueLogId: this.props.measureValueLogId,
            patientId: this.props.patientId,
            measureId: this.props.measureId
        })
    }
    save = () => {
        this.setState({ saving: true })
        return this.props.saveEdits({
            measureValueLogId: this.props.measureValueLogId,
            measureId: this.props.measureId,
            dataType: this.props.dataType,
            dropListOptions: this.props.dropListOptions,
            values: this.state.values,
            logDt: this.props.logDt,
            subMeasures: this.props.subMeasureValues?.map(
                this.getSubmeasureValueData
            )
        })
    }

    getSubmeasureValueData = ({ dataType, measureId, measureValueLogId }) => {
        const value = this.state.values[measureId]
        return {
            measureId,
            measureValueLogId,
            codeDescId: dataType === C.ENUMERATION ? value : null,
            textVal: dataType === C.MULTISELECT ? joinNonNil(value, ";") : null,
            numericVal: [C.NUMERIC, C.ALPHANUMERIC].includes(dataType)
                ? value
                : null
        }
    }

    getErrors = () => {
        switch (this.props.dataType) {
            case C.NUMERIC:
            case C.ALPHANUMERIC:
                return _.pickBy({
                    [this.props.measureId]: getError(
                        this.state.values[this.props.measureId],
                        this.props.dataType,
                        this.props.minValue,
                        this.props.maxValue,
                        ""
                    )
                })
            case C.COMPOSITE:
                return _.pickBy(
                    itemsToObject(
                        this.props.subMeasureValues,
                        submeasureDef => submeasureDef.id,
                        submeasureDef =>
                            getError(
                                this.state.values[submeasureDef.id],
                                submeasureDef.dataType,
                                submeasureDef.minValue,
                                submeasureDef.maxValue,
                                submeasureDef.name
                            )
                    )
                )
            default:
        }
        return {}
    }

    render() {
        const sourceIsHealthFocus =
            this.props.sourceName === C.HEALTH_FOCUS_SOURCE_NAME

        const moduleIsEnabled = this.props.moduleIsEnabled ?? true

        const belongsToUser =
            this.props.isSuperuser ||
            this.props.currentUser.toLowerCase() ===
                this.props.loginId.toLowerCase()

        const permitted =
            sourceIsHealthFocus && moduleIsEnabled && belongsToUser

        const sharedProps = {
            oddRow: this.props.oddRow,
            userSource: sourceIsHealthFocus
                ? this.props.loginId
                : this.props.sourceName,
            serviceCodeSystem: this.props.serviceCodeSystem,
            serviceCode: this.props.serviceCode,
            serviceDt: this.props.serviceDt,
            logDt: this.props.logDt,
            measureId: this.props.measureId,
            dataType: this.props.dataType
        }

        return this.state.editing ? (
            <EditRow
                {...sharedProps}
                values={this.state.values}
                saving={this.state.saving}
                onChange={this.onChange}
                dropListOptions={this.props.dropListOptions}
                valid={Object.keys(this.getErrors()).length === 0}
                saveEdits={this.save}
                cancelEdits={this.cancel}
                errors={this.getErrors()}
                subMeasureValues={this.props.subMeasureValues}
                onCompositeChange={this.onCompositeChange}
            />
        ) : (
            <DisplayRow
                {...sharedProps}
                value={this.props.displayValue}
                restricted={!permitted}
                allowEdit={this.props.allowEdit}
                startEdit={this.edit}
                deleteItem={this.delete}
            />
        )
    }
}
