import React from "react"
import { connect } from "react-redux"
import { reduxForm, reset } from "redux-form"
import LEHOC from "../../core/LoadingErrorHOC"
import fetcher from "../../core/fetcher"
import { moduleEnabledSelector } from "../../moduleUtils"
import { ApiTypes } from "../../api"
import { MODULE_IDS } from "../../constants"
import {
    inputLookupSelector,
    measureAbbrevMapSelector,
    relevantCategoriesSelector
} from "../selectors"
import { validate, warn } from "../validators"
import * as reduxActions from "../actions"
import * as C from "../constants"
import AddMeasureModal from "./add_measure"
import MeasureCategory from "./MeasureCategory"
import { DisabledContext } from "./contexts"
import SnackBar from "./SnackBar"

export { AddMeasureModal }

export const MeasureSection = ({
    categories,
    change,
    clearFields,
    disableMeasures,
    disableScreenings,
    error,
    handleSubmit,
    loading
}) => (
    <DisabledContext.Provider value={{ disableMeasures, disableScreenings }}>
        <div
            id="segment-measures"
            role="tablist"
            className="widget compress-font card card-default card-group card-table"
            style={{ boxShadow: "none" }}
        >
            <LEHOC loading={loading} error={error} list={categories}>
                <div className="poc_measures">
                    {categories.map(category => (
                        <MeasureCategory
                            key={category.name}
                            category={category}
                            actions={{ change, clearFields }}
                        />
                    ))}
                </div>
            </LEHOC>
            <SnackBar handleSubmit={handleSubmit} />
        </div>
    </DisabledContext.Provider>
)

const ReduxMeasureForm = reduxForm({
    form: C.POC_MEASURE_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
    warn
})(MeasureSection)

const allowEditMeasures = moduleEnabledSelector(MODULE_IDS.POC_MEASURES)
const allowEditScreenings = moduleEnabledSelector(MODULE_IDS.POC_SCREENINGS)
export default connect(
    state => ({
        measures: measureAbbrevMapSelector(state),
        categories: relevantCategoriesSelector(state),
        inputLookup: inputLookupSelector(state),
        initialValues: {},
        disableMeasures: !allowEditMeasures(state),
        disableScreenings: !allowEditScreenings(state)
    }),
    { ...reduxActions, onSubmit: reduxActions.saveMeasures, reset }
)(
    fetcher({
        name: C.RELEVANT_MEASURES_TREE,
        endpoint: state => `/api/patients/${state.poc_id}/measures`,
        method: ApiTypes.GET
    })(ReduxMeasureForm)
)
