import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest
} from "redux-saga/effects"
import { reset } from "redux-form"
import moment from "moment"
import _ from "lodash"
import { modifyResource } from "../core/fetcher"
import { ApiTypes, tryFetch } from "../api"
import { urlWithParams } from "../utils"
import { DATE_STRING, SERVER_DATE_STRING } from "../dates"
import { notifySimpleError, tryWithNotifications } from "../notifications"
import { inputLookupSelector } from "./selectors"
import { formatPercentile, getInputDateName, getInputName } from "./helpers"
import {
    insertNewMeasure,
    removeMeasureHistoricalItem,
    updateLastEntry,
    updateMeasureHistory,
    updateMetrics
} from "./transforms"
import * as C from "./constants"
import { hideSnackbar, markMeasureDone, markMeasureSaving } from "./actions"
import {
    measureErrorNotification,
    measureSuccessNotification
} from "./components/MeasureNotifications"

export function* newMeasures() {
    const new_measures = yield select(
        state => state.form[C.ADD_MEASURE_TREE_FORM].values.measures
    )
    const measureIds = new_measures.map(item => Number(item))
    yield* tryFetch(
        {
            url: urlWithParams(`/api/measures/detail`, { ids: measureIds })
        },
        function*(body) {
            for (let newData of body) {
                const newMeasure = { ...newData, hasRecentValue: false }
                yield put(
                    modifyResource({
                        name: C.RELEVANT_MEASURES_TREE,
                        dataTransform: data =>
                            insertNewMeasure(data, newMeasure)
                    })
                )
            }
        },
        notifySimpleError("Unable to get details for new measures")
    )
}

const getTextVal = (formValue, dataType, measureInputName) => {
    if (measureInputName?.match(C.BMI_PERCENTILE_ABBREV_PATTERN)) {
        return formatPercentile(formValue)
    }
    if (dataType === C.MULTISELECT) {
        return formValue.join(";") // DB function expects multiselect terms as a semicolon-separated "list" string
    }
    if (
        dataType === C.COMPOSITE ||
        dataType === C.NUMERIC ||
        dataType === C.ALPHANUMERIC
    ) {
        return formValue
    }

    return null
}

const getCodeDescId = (formValue, dataType, normalDropListOptions) => {
    if (dataType === C.ENUMERATION) {
        return Number(formValue)
    }
    if (dataType === C.CHECKBOX) {
        return (
            Number(_.find(normalDropListOptions, cur => cur.id)?.id) ??
            C.DEFAULT_CODE_DESC_ID
        )
    }
    return C.DEFAULT_CODE_DESC_ID
}

const formatMeasure = (
    formValues,
    nameLookup,
    patientId,
    indexedSubmeasures
) => measureInputName => {
    const formValue = formValues[getInputName(measureInputName)]
    const formValueDate = formValues[getInputDateName(measureInputName)]
    const serviceDt = moment
        .utc(formValueDate, DATE_STRING)
        .format(SERVER_DATE_STRING)

    const { dataType, measureId, normalDropListOptions } =
        nameLookup[getInputName(measureInputName)] || {}

    return {
        patientId,
        measureId,
        numericVal:
            dataType === C.NUMERIC || dataType === C.ALPHANUMERIC
                ? Number(formValue) // XXX at the moment, alphanumeric inputs actually have to be numeric
                : null,
        textVal: getTextVal(formValue, dataType, measureInputName),
        codeDescId: getCodeDescId(formValue, dataType, normalDropListOptions),
        serviceDt,
        subMeasures: indexedSubmeasures[measureId]?.map(sm => ({
            ...sm,
            serviceDt
        }))
    }
}

const formatSubmeasure = (
    patientId,
    nameLookup,
    formValues
) => submeasureInputName => {
    const submeasurePieces = submeasureInputName.split("/")
    const submeasure = nameLookup[submeasureInputName]
    const parentMeasure = nameLookup[submeasurePieces[0]]
    const value = formValues[submeasureInputName]

    const isEnumeration = submeasure?.dataType === C.ENUMERATION
    const isMultiselect = submeasure?.dataType === C.MULTISELECT

    return {
        patientId,
        parentId: parentMeasure?.id,
        measureId: submeasure?.id,
        numericVal: isEnumeration || isMultiselect ? null : value,
        textVal: isMultiselect ? value.join(";") : null,
        codeDescId: isEnumeration ? +value : C.DEFAULT_CODE_DESC_ID,
        subMeasures: null
    }
}

function* getMeasureValues() {
    const patientId = yield select(state => state.poc_id)
    const inputLookup = yield select(state => inputLookupSelector(state))
    const formValues = yield select(
        state => state.form[C.POC_MEASURE_FORM].values
    )

    const measures = _.keys(formValues).filter(
        item =>
            !item.includes("/date") &&
            !item.includes("/sub/") &&
            !item.includes("/comp/") &&
            formValues[item]
    )

    const submeasures = _.keys(formValues)
        .filter(item => item.includes("/sub/") || item.includes("/comp/"))
        .map(formatSubmeasure(patientId, inputLookup, formValues))

    const indexedSubmeasures = _.groupBy(submeasures, item => item.parentId)

    //mark measures loading
    for (const measureAbbrev of measures) {
        const measureId = inputLookup[measureAbbrev]?.id
        yield put(markMeasureSaving(measureId))
    }

    // Format measures
    return measures.map(
        formatMeasure(formValues, inputLookup, patientId, indexedSubmeasures)
    )
}

function* markMeasuresDone(values) {
    for (const value of values) {
        yield put(markMeasureDone(value.measureId))
    }
}

export function* saveMeasureToServer() {
    const values = yield* getMeasureValues()
    const patientId = yield select(state => state.poc_id)

    yield put(hideSnackbar())

    yield* tryWithNotifications(
        {
            url: "/api/measures/value",
            method: ApiTypes.POST,
            body: { values }
        },
        function*({ metrics }) {
            // Update Locally
            for (let newValue of values) {
                const newMeasure = {
                    ...newValue,
                    ...metrics,
                    logDt: metrics.logDt,
                    hasRecentValue: true
                }
                yield put(
                    modifyResource({
                        name: C.RELEVANT_MEASURES_TREE,
                        dataTransform: data => updateLastEntry(data, newMeasure)
                    })
                )
            }

            yield put(
                modifyResource({
                    name: C.RELEVANT_MEASURES_TREE,
                    dataTransform: data => updateMetrics(data, metrics)
                })
            )

            yield put(reset(C.POC_MEASURE_FORM))
            yield* markMeasuresDone(values)
            return measureSuccessNotification(
                "The new measure values were saved successfully!"
            )
        },
        function*(error) {
            yield* markMeasuresDone(values)
            return measureErrorNotification(
                error,
                "Failed to save new measure values.",
                { patientId }
            )
        }
    )
}

export function* saveEdits(action) {
    const {
        measureValueLogId,
        values,
        dataType,
        dropListOptions,
        measureId,
        subMeasures
    } = action.payload
    const patientId = yield select(state => Number(state.poc_id))
    const formValue = values[measureId]
    let numericVal = null
    let codeDescId = null
    let textVal = null
    switch (dataType) {
        case C.COMPOSITE:
        case C.MULTISELECT:
            textVal = formValue
            break
        case C.CHECKBOX:
            codeDescId = dropListOptions[0].id
            break
        case C.ENUMERATION:
            codeDescId = Number(formValue)
            break
        case C.ALPHANUMERIC:
        case C.NUMERIC:
        default:
            numericVal = Number(formValue)
    }
    const data = {
        measureValueLogId,
        numericVal,
        textVal,
        codeDescId,
        subMeasures:
            subMeasures && subMeasures.map(item => ({ ...item, patientId })),
        patientId
    }

    yield put({ type: C.HIDE_SNACKBAR })

    yield* tryWithNotifications(
        {
            url: `/api/measures/history/${measureValueLogId}`,
            method: ApiTypes.PUT,
            body: { data },
            message: "Saving new value in measure history..."
        },
        function*(received) {
            const newData = {
                ...received,
                hasRecentValue: true
            }
            yield put(
                modifyResource({
                    name: C.RELEVANT_MEASURES_TREE,
                    dataTransform: data => updateLastEntry(data, newData)
                })
            )

            yield put(
                modifyResource({
                    name: C.MEASURE_HISTORY,
                    dataTransform: updateMeasureHistory(
                        received,
                        dropListOptions,
                        dataType
                    )
                })
            )
            yield put(markMeasureDone(measureId))
            return measureSuccessNotification(
                "The measure value was updated successfully!"
            )
        },
        function*(error) {
            yield put(markMeasureDone(measureId))
            return measureErrorNotification(
                error,
                "Failed to update measure value.",
                data
            )
        }
    )
}

export function* removeMeasureHistoryItem(action) {
    const { measureValueLogId, patientId, measureId } = action.payload

    yield put({ type: C.HIDE_SNACKBAR })
    yield put(markMeasureSaving(measureId))

    yield* tryWithNotifications(
        {
            url: `/api/measures/history/${measureValueLogId}/patient/${patientId}`,
            method: ApiTypes.DELETE,
            message: "Deleting value from measure history..."
        },
        function*({ metrics }) {
            yield put(
                modifyResource({
                    name: C.MEASURE_HISTORY,
                    dataTransform: removeMeasureHistoricalItem(
                        measureValueLogId
                    )
                })
            )
            yield put(
                modifyResource({
                    name: C.RELEVANT_MEASURES_TREE,
                    dataTransform: data => updateMetrics(data, metrics)
                })
            )

            yield put(markMeasureDone(measureId))
            return measureSuccessNotification(
                "The measure value was deleted successfully!"
            )
        },
        function*(error) {
            yield put(markMeasureDone(measureId))
            return measureErrorNotification(
                error,
                "Failed to delete measure value.",
                { patientId }
            )
        }
    )
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeLatest(C.SAVE_MEASURES, saveMeasureToServer)
        }),
        call(function*() {
            yield takeLatest(C.ADD_NEW_MEASURES, newMeasures)
        }),
        call(function*() {
            yield takeEvery(C.SAVE_EDITS, saveEdits)
        }),
        call(function*() {
            yield takeEvery(C.REMOVE_HISTORICAL_ITEM, removeMeasureHistoryItem)
        })
    ])
}
