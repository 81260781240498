import _ from "lodash"
import { isNilOrBlank, isNilOrEmpty } from "../utils"
import { getAbbrevFromFieldName, getInputName } from "./helpers"
import * as C from "./constants"

const IS_REQUIRED = "is required"

function lacksDateBecauseAutocalc(fieldName, measures) {
    // autocalc measures can't have dates entered manually... unless they're composite measures
    const measureAbbrev = getAbbrevFromFieldName(fieldName)
    const measure = measures[measureAbbrev]
    return measure?.autoCalculated && measure?.measureDatatype !== C.COMPOSITE
}

export const validate = (values, { measures }) => {
    const errors = {}

    // regular fields
    Object.keys(values)
        .filter(
            fieldName =>
                values[fieldName] && // ignore any entries with falsy values. ("0" isn't falsy btw because it's a string)
                !fieldName.includes("/date") &&
                !fieldName.includes("/sub/") &&
                !fieldName.includes("/comp/") && // we don't want the composite submeasures, only the combined value
                !lacksDateBecauseAutocalc(fieldName, measures)
        )
        .forEach(fieldName => {
            if (isNilOrBlank(values[`${fieldName}/date`])) {
                errors[`${fieldName}/date`] = IS_REQUIRED
            }
        })

    // subfields (usually survey questions) require parent measure
    Object.keys(values)
        .filter(fieldName => fieldName.includes("/sub/") && values[fieldName])
        .forEach(fieldName => {
            const parentAbbrev = getAbbrevFromFieldName(fieldName)
            const parentIdName = getInputName(parentAbbrev)
            if (isNilOrBlank(values[parentIdName])) {
                errors[parentIdName] = IS_REQUIRED
            }
        })

    // dates
    Object.keys(values)
        .filter(fieldName => fieldName.includes("/date"))
        .forEach(fieldName => {
            const baseFieldName = fieldName.replace("/date", "")
            if (values[fieldName] && isNilOrBlank(values[baseFieldName])) {
                errors[baseFieldName] = IS_REQUIRED
            }
        })

    return errors
}

export const warn = (values, { inputLookup }) =>
    // warn if result submeasure didn't calculate properly
    _(values)
        .pickBy(
            (value, fieldName) =>
                fieldName.includes("/sub/") &&
                inputLookup[fieldName]?.autoCalc &&
                !_.isUndefined(value)
        )
        .mapValues((value, fieldName) => {
            if (value === null) {
                return "has no calculated result value"
            }
            if (isNilOrEmpty(inputLookup[fieldName]?.dropListOptions)) {
                return "has no description for its calculated result"
            }
        })
        .value()
