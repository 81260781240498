import React, { useCallback } from "react"
import _ from "lodash"
import fetcher from "../../../../../core/fetcher"
import LEHOC from "../../../../../core/LoadingErrorHOC"
import { ApiTypes } from "../../../../../api"
import * as C from "../../../../constants"
import HistoricalItem from "./HistoricalItem"

export const HistoryList = ({
    data,
    error,
    loading,
    subMeasures,
    ...props
}) => {
    const combineSubMeasureValues = useCallback(
        subMeasureValues =>
            _.map(subMeasures, (submeasure, index) => ({
                ...submeasure,
                ...subMeasureValues?.[index]
            })),
        [subMeasures]
    )

    return (
        <LEHOC loading={loading} error={error} list={data.values}>
            <table className="popover-table">
                <thead>
                    <tr>
                        <th>User/Source</th>
                        <th>Code System</th>
                        <th>Code</th>
                        <th>Value</th>
                        <th>Date</th>
                        <th>Logged</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {data.values?.map((item, index) => (
                        <HistoricalItem
                            key={`${index} | ${item.displayValue}`} // it'll refresh if the value changes
                            oddRow={index % 2 === 1}
                            {...props}
                            {...item}
                            subMeasureValues={combineSubMeasureValues(
                                item.subMeasureValues
                            )}
                        />
                    ))}
                </tbody>
            </table>
        </LEHOC>
    )
}

export default fetcher({
    name: C.MEASURE_HISTORY,
    endpoint: (state, { measureId, patientId }) =>
        `/api/measures/history/${measureId}/patient/${patientId}`,
    method: ApiTypes.GET,
    persistResource: true
})(HistoryList)
