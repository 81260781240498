import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import moment from "moment"
import { DATE_STRING, isoToUsDate, usToIsoDate } from "../dates"
import { callUntilTruthy, asArray, EMPTY_OBJECT } from "../utils"
import { useFocus } from "../hooks"
import DatePicker, { DatePickerButton } from "./DatePicker"
import { Hoverlay } from "./Hoverlay"

export const DateEntry = props => {
    const {
        cellData,
        rowData,
        disableClear,
        error,
        updateError,
        clearError,
        validate,
        canWrite,
        saveDate,
        getPayload = () => EMPTY_OBJECT
    } = props

    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(cellData) // note that the data needs to be already formatted in US format
    // we're storing the value in the state because it's temporary. Once it's saved, it'll update in the store... and that'll change the key of this component, causing a full rerender.

    const { focused, setFocus, focusRef } = useFocus()

    useEffect(() => {
        if (loading && error) {
            setLoading(false)
            setValue("")
        }
    }, [loading, error])

    useEffect(() => {
        return () => {
            if (error) {
                clearError(getPayload()) // the errored value is gone at this point, so clear the error
            }
        }
    }, [error])

    const handleChange = value => {
        setValue(value)
        clearError(getPayload())
    }

    const handleSubmit = value => {
        const payload = getPayload()

        if (value === cellData) {
            clearError(payload)
            return
        }

        // XXX note that most date validators use MM/DD/YYYY format by default, but most tables store dates in YYYY-MM-DD format. Make sure that any validators that pull dates from elsewhere in the table specify a startDateFormat or endDateFormat of SERVER_DATE_STRING in the validator options. e.g.:
        /*  columnData: {
         *      validate: afterDateField(<some date column>, {
         *          startDateFormat: SERVER_DATE_STRING
         *      })
         *  }
         */

        const normalizedValue = usToIsoDate(value)

        if (value && !normalizedValue) {
            // formattedValue can be blank if the date is invalid or equal to START_OF_TIME or END_OF_TIME
            updateError({ ...payload, errorMessage: "Invalid date" })
            return
        }

        const errorMessage = callUntilTruthy(
            asArray(validate),
            value,
            rowData,
            props
        )

        if (errorMessage) {
            updateError({ ...payload, errorMessage })
            return
        }

        setValue(value)
        setLoading(true)
        saveDate({
            ...payload,
            value: normalizedValue
        })
    }

    const handleButtonClick = value => {
        setFocus()
        handleSubmit(value)
    }

    if (!canWrite) {
        return cellData
    }
    if (!focused) {
        return (
            <div className="datepicker datepicker-cell">
                <span
                    className={classNames(
                        "form-control",
                        !value && "datepicker-placeholder"
                    )}
                    onFocus={setFocus}
                    tabIndex={0}
                >
                    {value}
                </span>
                <DatePickerButton
                    value={value}
                    setDateToday={() =>
                        handleButtonClick(moment().format(DATE_STRING))
                    }
                    clearDate={() => handleButtonClick("")}
                />
            </div>
        )
    }

    return (
        <Hoverlay
            disabled={!error}
            tooltip={error}
            className="tooltip-red"
            show
        >
            <DatePicker
                ref={focusRef}
                className="datepicker-cell"
                defaultValue={cellData}
                value={value}
                onBlur={handleSubmit}
                onChange={handleChange}
                loading={loading}
                disabled={loading}
                disableClear={disableClear}
                error={error}
                validDates={validate}
                formValues={rowData}
            />
        </Hoverlay>
    )
}

// this doesn't strictly have to be a connect(), but it's a little easier this way
export default connect(
    (
        state,
        { cellData, rowData, dataKey, keyGetter = ({ cellData }) => cellData }
    ) => ({
        key: keyGetter({ cellData, rowData }), // this makes the cell rerender when it's done saving a value
        cellData: isoToUsDate(cellData)
    })
)(DateEntry)
