import React from "react"
import { Field } from "redux-form"
import { SelectField } from "../../../../../core/CustomFields"
import * as C from "../../../../constants"
import { CheckboxField } from "../CustomFields"
import { NumericWrapper } from "../NumericWrapper"
import { MULTISELECT } from "../../../../constants"
import { formatDropListOptions } from "../../../../helpers"

export const CompositeSubMeasure = props => {
    switch (props.dataType) {
        case C.ALPHANUMERIC:
        case C.NUMERIC:
            return <NumericWrapper {...props} />
        case C.ENUMERATION:
        case C.MULTISELECT:
            return (
                <Field
                    {...props}
                    component={SelectField}
                    options={formatDropListOptions(props.dropListOptions)}
                    multi={props.dataType === MULTISELECT}
                />
            )
        case C.CHECKBOX:
            return <Field {...props} component={CheckboxField} /> // TODO this probably isn't correct, because CheckboxField can't actually have a value entered. We need to make a different field, or add the capacity for this one to be sometimes value-enterable
        default:
            return <div />
    }
}

export default CompositeSubMeasure
