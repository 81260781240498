import { objToValueLabel, valueLabelToObj } from "./utils"

export const FILTER_OPTIONS = "FILTER_OPTIONS"
export const MEASURES = "MEASURES"
export const SETTINGS = "SETTINGS"
export const PATIENT_ID = "patientId"

export const PATH = "path"

export const REHYDRATE = "persist/REHYDRATE"

export const COMPLIANCE = {
    COMPLIANT: "M",
    NOT_COMPLIANT: "X",
    PENDING: "P"
}

export const POPULATION_VALUES = {
    ALL: "A",
    INCENTIVIZED: "I"
}

export const POPULATION_LABELS_SHORT = {
    [POPULATION_VALUES.ALL]: "All",
    [POPULATION_VALUES.INCENTIVIZED]: "Incentivized"
}
export const POPULATION_LABELS = {
    [POPULATION_VALUES.ALL]: "All Patients",
    [POPULATION_VALUES.INCENTIVIZED]: "Incentivized Patients"
}
export const POPULATION_OPTIONS = objToValueLabel(POPULATION_LABELS)

export const ALIGNMENTS = { RIGHT: "right", LEFT: "left", CENTER: "center" }

export const BOOLEAN_VALUES = [true, false]
export const BOOLEAN_OPTIONS = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
]
export const BOOLEAN_LABELS = valueLabelToObj(BOOLEAN_OPTIONS)

export const YES_NO_VALUES = {
    YES: "Y",
    NO: "N"
}
export const YES_NO_LABELS = {
    [YES_NO_VALUES.YES]: "Yes",
    [YES_NO_VALUES.NO]: "No"
}
export const YES_NO_OPTIONS = objToValueLabel(YES_NO_LABELS)

export const LOCAL = "LOCAL"
export const GLOBAL = "GLOBAL"

export const SCOPE_LABELS = {
    [LOCAL]: "My Panel(s)",
    [GLOBAL]: "All Patients"
}
export const SCOPE_OPTIONS = objToValueLabel(SCOPE_LABELS)

export const PRODUCT_ABBREVS = {
    1: "C",
    2: "E",
    3: "Md",
    4: "Mr"
}

export const STATE_ABBREVS = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "FA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY"
]

export const STATE_OPTIONS = STATE_ABBREVS.map(state => ({
    label: state,
    value: state
}))

export const FIELD_STATES = {
    DISABLED: "disabled",
    ERROR: "form-field-error",
    WARNING: "form-field-warning",
    VALID: "form-field-valid"
}
export const MODULE_IDS = {
    CARE_MANAGEMENT: 1,
    TRANSITIONS_OF_CARE: 2,
    CAMPAIGNS: 3,
    QUALITY_SUMMARY: 4,
    COST_UTILIZATION: 5,
    REPORTING: 6,
    POINT_OF_CARE: 7,
    POC_MEASURES: 8,
    DEACTIVATE_PATIENT: 9,
    ADD_EDIT_PATIENT: 10,
    COMMUNITY_RESOURCES: 11,
    COMMUNITY_LINKAGES: 12,
    DATA_COVERAGE: 13,
    INCENTIVE_TRACKING: 14,
    RISK: 15,
    TCM_READ: 16,
    TCM_WRITE: 17,
    AWV: 18,
    PROVIDER_DIRECTORY: 19,
    CCDA: 20,
    AUDITS: 21,
    AUDITS_UPLOAD: 22,
    EXTRACTS: 23,
    SPENDING_CHART: 24,
    DOCUMENTS: 25,
    DOCUMENT_UPLOAD: 26,
    RISK_REVENUE: 27,
    SPECIALIST_DIRECTORY: 28,
    POC_SCREENINGS: 29
}

export const DASHBOARD_MODULES = [
    MODULE_IDS.CARE_MANAGEMENT,
    MODULE_IDS.COST_UTILIZATION,
    MODULE_IDS.QUALITY_SUMMARY,
    MODULE_IDS.TRANSITIONS_OF_CARE,
    MODULE_IDS.CAMPAIGNS,
    MODULE_IDS.DATA_COVERAGE
]
