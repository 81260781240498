import { connect } from "react-redux"
import fetcher, { getResourceDataAsArray } from "../../../core/fetcher"
import { ApiTypes } from "../../../api"
import { usersSelector } from "../../selectors"
import * as C from "../../constants"
import UserPanel from "./UserPanel"

export default fetcher({
    name: C.USERS,
    method: ApiTypes.GET,
    requestBodySelector: state => ({
        updateCount: state[C.ADMIN_NAME].userTableUpdateCount
    }),
    endpoint: "/api/users"
})(
    connect(state => ({
        practices: getResourceDataAsArray(C.PRACTICE_UNITS)(state),
        users: usersSelector(state)
    }))(UserPanel)
)
