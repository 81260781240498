import React, { useEffect, useMemo } from "react"
import { Field } from "redux-form"
import _ from "lodash"
import { SelectField } from "../../../../../core/CustomFields"
import {
    formatDropListOptions,
    getCompositeSubmeasureInputName,
    getInputName
} from "../../../../helpers"
import { getMeasureDisplay, joinSubmeasureValues } from "../../../../transforms"
import * as C from "../../../../constants"
import validatorLookup from "./validators"
import CompositeSubMeasure from "./CompositeSubMeasure"

export const CompositeMeasures = props => {
    const {
        abbrevName,
        subMeasures,
        formValues,
        disabled,
        change,
        dropListOptions,
        unitLabel
    } = props

    const name = useMemo(() => getInputName(abbrevName), [abbrevName])

    const options = useMemo(
        () => formatDropListOptions(dropListOptions),
        // note that there won't be any options if this isn't an ENUMERATION or MULTISELECT input, and that's fine
        [dropListOptions]
    )

    const submeasureProps = useMemo(
        () =>
            subMeasures?.map(subMeasure => ({
                ...subMeasure,
                placeholder: subMeasure.name,
                nameText: subMeasure.name,
                name: getCompositeSubmeasureInputName(abbrevName)(subMeasure)
            })),
        [subMeasures, abbrevName]
    )

    useEffect(() => {
        change(
            C.POC_MEASURE_FORM,
            name,
            joinSubmeasureValues(
                submeasureProps,
                getMeasureDisplay(formValues, C.TABLE_KEYS.NAME),
                unitLabel
            )
        )
    }, [disabled, submeasureProps, formValues, name])

    const validators = useMemo(() => {
        const validatorsGetter = _.find(validatorLookup, (value, key) =>
            name.match(key)
        )

        if (!validatorsGetter) {
            console.warn("No composite-measure validator found for", name)
            return []
        }
        return validatorsGetter({ abbrevName, subMeasures })
    }, [name, abbrevName, subMeasures])

    return (
        <div className="measure-composite">
            <Field
                {...props}
                name={name}
                validate={validators}
                style={{ display: "none" }}
                component={SelectField}
                options={options}
            />
            {submeasureProps?.map(smProps => (
                <CompositeSubMeasure
                    key={smProps.id}
                    {...smProps}
                    disabled={smProps.disabled}
                    className="submeasure-composite"
                />
            ))}
        </div>
    )
}
