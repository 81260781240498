import React, { Component } from "react"
import { Field } from "redux-form"
import { Button, Col, Modal, Row } from "react-bootstrap"
import _ from "lodash"
import Loading from "../../../core/Loading"
import FormModal from "../../../core/FormModal"
import PatientSelect from "../../../core/PatientSelect"
import { GuideLink } from "../../../indicators"
import * as C from "../../constants"
import { shouldDisablePrint } from "../../helpers"
import { PrintField } from "../PrintField"
import { PrintCosts } from "../PrintCosts"
import { PrintLetter } from "../PrintLetter"
import { PrintLinkages } from "../PrintLinkages"
import { PrintAssessments } from "../PrintAssessments"
import { BatchPrintTogether } from "./BatchPrintTogether"
import { SelectedPatientsList } from "./SelectedPatientsList"
import { PrintConditions } from "../PrintConditions"
import { PrintMeasureHistory } from "../PrintMeasureHistory"

export class BatchPrintModal extends Component {
    state = {
        // storing this here because it's slightly awkward to use with redux-form
        patients: []
    }
    handleSubmit = () =>
        this.props.submitBatchPrint({
            patients: this.state.patients,
            ...this.props.formValues
        })

    componentDidUpdate(prevProps) {
        if (
            _.isEmpty(this.props.patients) &&
            (!_.isEmpty(prevProps.patients) ||
                !_.isEqual(
                    prevProps.campaign_patients,
                    this.props.campaign_patients
                ))
        ) {
            // i.e. update state using props.campaign_patients if props.patients stops having anything in it, or if we're already using props.campaign_patients and it changes.
            this.setState({ patients: this.props.campaign_patients })
        } else if (!_.isEqual(prevProps.patients, this.props.patients)) {
            this.setState({ patients: this.props.patients })
        }
    }

    addPatient = patients =>
        this.setState({
            patients
        })
    removePatient = patient =>
        this.setState({
            patients: this.state.patients.filter(
                item => item.value !== patient.value
            )
        })
    removeAll = () => this.setState({ patients: [] })

    render() {
        return (
            <FormModal
                className="batch-print"
                size="lg"
                show={this.props.show_bp}
                onHide={this.props.hideBatchPrint}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Batch Print
                        <GuideLink
                            page="batch-print-patient-details"
                            className="white"
                        />
                    </Modal.Title>
                </Modal.Header>
                {this.props.printing ? (
                    <div className="printing">
                        <Loading />
                        <p>
                            Generating Package: {this.props.downloadPercentage}%
                        </p>
                    </div>
                ) : (
                    <Modal.Body>
                        <Row>
                            <Col lg={5}>
                                <h5>Please select patients to print</h5>
                                <PatientSelect
                                    onChange={this.addPatient}
                                    selectedPatients={this.state.patients}
                                />

                                <hr />

                                <div className="batch-print-section-header">
                                    <h5>Patients to be printed</h5>
                                    {this.state.patients.length !== 0 && (
                                        <button
                                            className="batch-print-remove_all"
                                            onClick={this.removeAll}
                                        >
                                            Remove All
                                        </button>
                                    )}
                                </div>
                                <SelectedPatientsList
                                    patients={this.state.patients}
                                    onClickPatient={this.removePatient}
                                />
                            </Col>

                            <Col lg={7}>
                                <div className="batch-print-section-header">
                                    <h5>Sections to include</h5>
                                    <div>(Empty sections will be skipped)</div>
                                </div>

                                <PrintAssessments {...this.props} />
                                <PrintField name={C.PRINT_FIELDS.ATTENTION} />
                                <PrintConditions />
                                <PrintMeasureHistory {...this.props} />
                                <PrintLetter {...this.props} />
                                <PrintLinkages />
                                <PrintCosts
                                    canAccessCosts={this.props.canAccessCosts}
                                />
                                <PrintField name={C.PRINT_FIELDS.FQHC} />
                            </Col>
                        </Row>
                        <Field
                            name={C.PRINT_FIELDS.SINGLE_PDF}
                            component={({ input }) =>
                                this.state.patients.length > 1 && (
                                    <BatchPrintTogether input={input} />
                                )
                            }
                        />
                    </Modal.Body>
                )}
                {!this.props.printing && (
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            className="btn-modal-close"
                            onClick={this.props.hideBatchPrint}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            className="btn-modal-save"
                            onClick={this.handleSubmit}
                            disabled={
                                this.state.patients.length === 0 ||
                                shouldDisablePrint(this.props.formValues)
                            }
                        >
                            Print
                        </Button>
                    </Modal.Footer>
                )}
            </FormModal>
        )
    }
}
