import React from "react"
import { SortDirection } from "react-virtualized"
import { Link } from "react-router-dom"
import { Card } from "react-bootstrap"

import LEHOC from "../../core/LoadingErrorHOC"
import FilterHeader from "../../core/FilterHeader"
import CardTitle from "../../core/CardTitle"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import DashboardFilters from "../../dashboard_filters/components"
import { GuideLink } from "../../indicators"
import { NO_TABLE, tableFactory } from "../../table"
import { constants as PC, ButtonGroup } from "../../pickers"

import * as C from "../constants"
import {
    NETWORK_TABLE_COLUMNS,
    PRACTICE_TABLE_COLUMNS,
    PROVIDER_TABLE_COLUMNS
} from "../columns"
import { getTableName } from "../helpers"
import { FiltersForm } from "./FiltersForm"

const createTable = (aggregation, columns) =>
    tableFactory({
        name: getTableName(aggregation),
        columns,
        sortBy: [C.AGGREGATION_MEASURES[aggregation]],
        sortDirection: [SortDirection.ASC]
    })

const AGGREGATION_TABLES = {
    [PC.VIEWS.NETWORK]: createTable(PC.VIEWS.NETWORK, NETWORK_TABLE_COLUMNS),
    [PC.VIEWS.PRACTICE]: createTable(PC.VIEWS.PRACTICE, PRACTICE_TABLE_COLUMNS),
    [PC.VIEWS.PROVIDER]: createTable(PC.VIEWS.PROVIDER, PROVIDER_TABLE_COLUMNS)
    // they all have to be separate because if they shared one set of column settings, then saving any column structure in Network view would hide the Practice Unit column in Practice view.
}

export const QSRDashboard = props => {
    const { qsrs, loading, error, aggregation } = props

    const { Settings, Table, Description } =
        AGGREGATION_TABLES[aggregation] ?? NO_TABLE

    return (
        <Card className="qsr-dashboard dashboard-table card-table">
            <Card.Header>
                <div className="dashboard-table-controls">
                    <div className="dashboard-table-title">
                        <CardTitle>
                            <Link to="/reports/quality">
                                Quality Summary Report
                            </Link>
                            <GuideLink page="view-quality-summary-dashboard" />
                        </CardTitle>

                        <DashboardFilters
                            {...props}
                            title="Edit QSR Filters"
                            form={C.QSR_FILTERS_FORM}
                            formComponent={FiltersForm}
                            initialValues={props.filters}
                            emptyValues={C.initialState.filters}
                            size="lg"
                        />
                    </div>

                    <ButtonGroup
                        value={props.aggregation}
                        onClick={props.setAggregation}
                        options={PC.VIEW_OPTIONS}
                    />

                    <ExportIcon onClick={props.exportQSR} />
                    <PrintIcon onClick={props.printQSR} />
                    <Settings />
                </div>
                <FilterHeader
                    form={C.QSR_FILTERS_FORM}
                    filters={props.filters}
                />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC loading={loading} error={error} list={qsrs}>
                    <Table list={props.qsrs} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}
