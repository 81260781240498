import React from "react"
import { Field } from "redux-form"
import { ErrorBoundary } from "react-error-boundary"
import { SelectField } from "../../../../core/CustomFields"
import { formatDropListOptions, getMeasureInputName } from "../../../helpers"
import * as C from "../../../constants"
import Survey from "./Survey"
import { CheckboxField } from "./CustomFields"
import AutoCalculated from "./AutoCalculated"
import CompositeMeasure from "./CompositeMeasure"
import NumericWrapper from "./NumericWrapper"
import AutocalcErrorFallback from "./AutocalcErrorFallback"

export const SimpleMeasureInput = ({
    measure,
    disabled,
    nameGetter = getMeasureInputName
}) => {
    if (!measure.allowEntry) {
        return null
    }

    switch (measure.dataType) {
        case C.NUMERIC:
        case C.ALPHANUMERIC:
            return (
                <NumericWrapper
                    {...measure}
                    disabled={disabled}
                    name={nameGetter(measure)}
                    nameText={measure.name}
                />
            )
        case C.CHECKBOX:
            return (
                <Field
                    {...measure}
                    disabled={disabled}
                    name={nameGetter(measure)}
                    nameText={measure.name}
                    component={CheckboxField}
                />
            )
        case C.ENUMERATION:
        case C.MULTISELECT:
            return (
                <Field
                    {...measure}
                    disabled={disabled}
                    name={nameGetter(measure)}
                    nameText={measure.name}
                    options={formatDropListOptions(measure.dropListOptions)}
                    component={SelectField}
                    multi={measure.dataType === C.MULTISELECT}
                />
            )
        default:
            return null
    }
}

export const MeasureInput = ({ disabled, measure }) => {
    if (measure.dataType === C.COMPOSITE) {
        return <CompositeMeasure {...measure} disabled={disabled} />
    }
    if (measure.autoCalc) {
        return (
            <ErrorBoundary fallbackRender={AutocalcErrorFallback}>
                <Field
                    {...measure}
                    measure={measure}
                    disabled={disabled}
                    name={getMeasureInputName(measure)}
                    nameText={measure.name}
                    component={AutoCalculated}
                />
            </ErrorBoundary>
        )
    }
    if (measure.subMeasures?.length > 0) {
        return (
            <ErrorBoundary fallback="Error">
                <Survey {...measure} disabled={disabled} />
            </ErrorBoundary>
        )
    }
    return <SimpleMeasureInput measure={measure} disabled={disabled} />
}

export default MeasureInput
