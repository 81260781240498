import { createSelector } from "reselect"
import _ from "lodash"
import { RELEVANT_MEASURES_TREE } from "../measures/constants"
import { getResourceData } from "../core/fetcher"
import { dateFormat } from "../dates"
import { COMPLIANCE, MEASURES } from "../constants"
import * as C from "./constants"
import { measureIsSurvey } from "../measures/helpers"

// these should probably be moved to a different file, since they're not actually selectors, and they're used outside this module
export const transformPatient = item => ({
    value: item.patientId,
    label: `${item.patName} (${dateFormat(item.patDob)}, ${item.patGender})`,
    first: item.patFirstName, // these are used for making file names
    last: item.patLastName
})

const transformAssessment = item => ({
    label: item.name,
    value: item.id,
    compliant: item.compliant === COMPLIANCE.NOT_COMPLIANT
})

const patientSelectorFor = patientList =>
    createSelector(
        getResourceData(patientList, data => data.patients),
        patients =>
            _.uniqBy(patients, patient => patient.patientId).map(
                transformPatient
            )
    )

export const patientSelector = patientSelectorFor(C.PATIENT_LIST)
export const campaignPatientSelector = patientSelectorFor(
    C.CAMPAIGN_PATIENT_LIST
)

export const pocAssessmentsSelector = createSelector(
    getResourceData(RELEVANT_MEASURES_TREE, data => data.categories),
    (categories = []) =>
        categories
            .flatMap(category => category.subCategories)
            .flatMap(subcategory => subcategory.measures)
            .filter(measureIsSurvey)
            .map(transformAssessment)
)
export const pocHistoryMeasuresSelector = createSelector(
    getResourceData(RELEVANT_MEASURES_TREE, data => data.categories),
    (categories = []) =>
        categories
            .flatMap(category => category.subCategories)
            .flatMap(subcategory => subcategory.measures)
            .filter(
                measure =>
                    measure.displayValue &&
                    measure.offerGraph /*|| measure.offerHistory*/
            ) // eventually we'll display history tables too, and at that time we'll want to offer any measure with a history
            .map(item => ({
                label: item.name,
                value: item.id
            }))
)

export const allHistoryMeasuresSelector = createSelector(
    getResourceData(MEASURES, data => data.measures),
    (measures = []) =>
        measures
            .filter(measure => measure.offerHistory)
            .map(item => ({
                label: item.name,
                value: item.measureId
            }))
)
