import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import {
    getFormSyncErrors,
    getFormSyncWarnings,
    getFormValues,
    isInvalid
} from "redux-form"
import _ from "lodash"
import { inputLookupSelector } from "../selectors"
import { getAbbrevFromFieldName } from "../helpers"
import * as C from "../constants"

const getInvalidRow = (key, errorText, name) => (
    <span key={key}>
        {name} {errorText}
    </span>
)

const Invalid = ({ formErrors, inputLookup }) =>
    _.entries(formErrors).map(([key, errorText]) => {
        if (key.includes("/date")) {
            // date field. dates just have a name in the lookup instead of an object
            return getInvalidRow(key, errorText, inputLookup[key])
        }

        if (key.includes("/sub")) {
            // submeasure field; display using parent's name
            const parentAbbrev = getAbbrevFromFieldName(key)
            const parentName = inputLookup[parentAbbrev]?.name
            return getInvalidRow(key, errorText, parentName)
        }

        if (key.includes("/comp")) {
            // composite field has a problem in its submeasures
            return getInvalidRow(key, errorText, inputLookup[key].name)
        }

        // otherwise it's a regular field
        return getInvalidRow(key, errorText, inputLookup[key].name)
    })

export const SnackBar = ({
    show,
    invalid,
    handleSubmit,
    formErrors,
    formWarnings,
    values,
    inputLookup
}) => {
    const noActiveFields = _.every(values, _.isNil)

    if (!show || noActiveFields) {
        return null
    }

    const hasWarnings = !_.isEmpty(formWarnings)
    return (
        <div
            id="snackbar"
            className={classNames({
                invalid
            })}
        >
            {invalid ? (
                <Invalid formErrors={formErrors} inputLookup={inputLookup} />
            ) : (
                <>
                    <div className="saveable">
                        <span>You have unsaved changes</span>
                        <button onClick={handleSubmit}>Save Changes</button>
                    </div>
                    {hasWarnings && (
                        <div className="warning">
                            <Invalid
                                formErrors={formWarnings}
                                inputLookup={inputLookup}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default connect(state => ({
    ...state[C.MEASURE_SNACKBAR],
    invalid: isInvalid(C.POC_MEASURE_FORM)(state),
    values: getFormValues(C.POC_MEASURE_FORM)(state),
    formErrors: getFormSyncErrors(C.POC_MEASURE_FORM)(state),
    formWarnings: getFormSyncWarnings(C.POC_MEASURE_FORM)(state),
    inputLookup: inputLookupSelector(state)
}))(SnackBar)
