import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData, getResourceDataAsArray } from "../core/fetcher"
import { getTopTagPrivileges } from "../user_tags/helpers"
import { itemsToObject } from "../utils"
import * as C from "./constants"
import { getAccessLevelSelector } from "./helperSelectors"
import { getPrimaryPractice } from "./components/users/helpers"

// each user's primary practice ID

export const primaryPracticeIdsSelector = createSelector(
    getResourceData(C.USERS, data => data.users),
    users =>
        itemsToObject(users, C.USER_TABLE_KEYS.USER_ID, user =>
            getPrimaryPractice(user, C.PRACTICE_TABLE_KEYS.PRACTICE_ID)
        )
)

// each user's effective primary practice ID, given the admin looking at them. Users from practices the admin can't access are given the zero practice instead of their own.

export const userEffectivePrimaryPracticesSelector = createSelector(
    getResourceData(C.PRACTICE_UNITS),
    primaryPracticeIdsSelector,
    (practices, userPracticeIds) => {
        const activePracticeIds = new Set(
            _.map(practices, C.PRACTICE_TABLE_KEYS.PRACTICE_ID)
        )

        return _.mapValues(userPracticeIds, userPracticeId =>
            activePracticeIds.has(userPracticeId) ? userPracticeId : 0
        )
    }
)

// the list of practices for the practices table

export const practicesSelector = createSelector(
    getResourceDataAsArray(C.PRACTICE_UNITS),
    practices => [C.PRACTICE_ZERO, ...practices]
)

// the list of users for the users table
export const usersSelector = createSelector(
    getResourceData(C.USERS, data => data.users),
    state => state[C.ADMIN_NAME].selectedPractices,
    userEffectivePrimaryPracticesSelector,
    getAccessLevelSelector,
    (users = [], selectedPractices, userPrimaryPracticeIds, getAccessLevel) =>
        users
            .filter(user =>
                selectedPractices.includes(
                    userPrimaryPracticeIds[user[C.USER_TABLE_KEYS.USER_ID]]
                )
            )
            .map((user = {}) => {
                const primaryPractice = getPrimaryPractice(user) ?? {}
                return {
                    ...user,
                    [C.USER_TABLE_KEYS.PRIMARY_PRACTICE]:
                        primaryPractice[C.USER_PRACTICE_KEYS.PRACTICE_NAME],
                    [C.USER_TABLE_KEYS.PRIMARY_PRACTICE_ACTIVE]:
                        primaryPractice[
                            C.USER_PRACTICE_KEYS.PRACTICE_STATUS
                        ] === C.ACTIVE &&
                        !!userPrimaryPracticeIds[
                            user[C.USER_TABLE_KEYS.USER_ID]
                        ], // inactive practices and ones that you don't have access to will be displayed as greyed-out
                    [C.USER_TABLE_KEYS.TAGS]: getTopTagPrivileges(
                        user[C.USER_TABLE_KEYS.TAGS]
                    ),
                    [C.USER_TABLE_KEYS.ACCESS_LEVEL]: getAccessLevel(user)
                }
            })
)
