import { connect } from "react-redux"
import { getResourceData, modifyResource } from "../../../../../core/fetcher"
import { isSuperuser } from "../../../../../authentication/selectors"
import { moduleEnabledSelector } from "../../../../../moduleUtils"
import { MODULE_IDS } from "../../../../../constants"
import * as C from "../../../../constants"
import * as reduxActions from "../../../../actions"
import { measureIsSurvey } from "../../../../helpers"
import PatientHistory from "./PatientHistory"

const measuresModule = moduleEnabledSelector(MODULE_IDS.POC_MEASURES)
const screeningsModule = moduleEnabledSelector(MODULE_IDS.POC_SCREENINGS)

export default connect(
    (state, { id, measureId, patientId, ...props }) => ({
        currentUser: state.authentication.loginId,
        moduleIsEnabled: measureIsSurvey(props)
            ? screeningsModule(state)
            : measuresModule(state),
        isSuperuser: isSuperuser(state),
        historyList: getResourceData(C.MEASURE_HISTORY)(state),
        patientId: patientId ?? state.poc_id,
        measureId: measureId ?? id
    }),
    { ...reduxActions, modifyResource }
)(PatientHistory)
