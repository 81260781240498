import React from "react"
import Select from "../../../../../core/Select"
import * as C from "../../../../constants"
import { itemsToLabelValue } from "../../../../../utils"

const InputComponent = ({
    dataType,
    errors,
    values,
    onChange,
    onCompositeChange,
    dropListOptions,
    subMeasureValues,
    measureId,
    disabled
}) => {
    switch (dataType) {
        case C.COMPOSITE:
            return (
                <div className="edit-measure-composite">
                    {subMeasureValues?.map((submeasure, index) => (
                        <InputComponent
                            key={index}
                            {...submeasure}
                            measureId={submeasure.id}
                            placeholder={submeasure.name}
                            errors={errors}
                            disabled={disabled}
                            values={values}
                            onChange={value =>
                                onCompositeChange?.(value, submeasure.id)
                            }
                        />
                    ))}
                </div>
            )
        case C.CHECKBOX:
            return (
                <span>
                    {dropListOptions.reduce((acc, cur) => cur.name, "")}
                </span>
            )
        case C.ENUMERATION:
            return (
                <select
                    className="edit-measure-select"
                    disabled={disabled}
                    value={values[measureId] || ""}
                    onChange={e => onChange(e.target.value)}
                >
                    {dropListOptions.map((item, index) => (
                        <option key={index} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            )
        case C.MULTISELECT:
            return (
                <Select
                    className="edit-measure-multiselect"
                    value={values[measureId]?.split?.(";")?.map?.(Number)}
                    onChange={value => onChange(value.join(";"))}
                    options={itemsToLabelValue(
                        dropListOptions,
                        item => item.name,
                        item => item.id
                    )}
                    multi
                />
            )
        case C.ALPHANUMERIC:
        case C.NUMERIC:
            return (
                <input
                    disabled={disabled}
                    className={!!errors[measureId] ? "error" : ""}
                    type="text"
                    value={values[measureId] || ""}
                    onChange={e => onChange(e.target.value)}
                />
            )
        default:
            return <div />
    }
}

export default InputComponent
