import {
    all,
    call,
    put,
    race,
    select,
    take,
    takeEvery
} from "redux-saga/effects"
import _ from "lodash"
import { reset } from "redux-form"
import Notifications from "react-notification-system-redux"
import { modifyResource } from "../core/fetcher"
import { ApiTypes } from "../api"
import { tryWithNotifications, UID_ERROR } from "../notifications"
import * as C from "./constants"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"
import { primaryPracticeIdsSelector } from "./selectors"
import { getAccessLevelSelector } from "./helperSelectors"
import { userIdsInPracticeSelector } from "./modalSelectors"

const EXTRA_PROCESSING_FIELDS = [
    C.USER_FIELDS.TAGS,
    C.USER_FIELDS.NEW_PASSWORD,
    C.USER_FIELDS.NOT_HANDLERS
]
const NON_EXTERNAL_FIELDS = [
    C.USER_FIELDS.USER_UNITS,
    C.USER_FIELDS.ADMIN_UNITS,
    C.USER_FIELDS.PROVIDERS,
    C.USER_FIELDS.HANDLERS
]

function* hideEditUserModal() {
    yield put(
        modifyResource({
            name: C.SELECTED_USER,
            dataTransform: () => undefined
        })
    )
    yield put(
        modifyResource({
            name: C.USER_PRACTICE_TAGS,
            dataTransform: () => undefined
        })
    )
    yield put(reset(C.EDIT_USER_FORM))
    yield put(Notifications.hide(UID_ERROR))
}
function* hideAddUserModal() {
    yield put(
        modifyResource({
            name: C.USER_PRACTICE_TAGS,
            dataTransform: () => undefined
        })
    )
    yield put(reset(C.ADD_USER_FORM))
    yield put(Notifications.hide(UID_ERROR))
}
function* hideAddPracticeModal() {
    yield put(reset(C.ADD_PRACTICE_UNIT_FORM))
    yield put(Notifications.hide(UID_ERROR))
}
function* hideEditPracticeModal() {
    yield put(reset(C.EDIT_PRACTICE_UNIT_FORM))
    yield put(Notifications.hide(UID_ERROR))
}

const getTagsForRequest = tagMap =>
    _.map(_.pickBy(tagMap), (privilege, tagId) => ({
        tagId,
        privilege
    }))

function* addPractice({ payload }) {
    yield* tryWithNotifications(
        {
            url: `/api/practice_units`,
            body: {
                ...payload,
                tags: getTagsForRequest(payload[C.PRACTICE_FIELDS.TAGS])
            },
            method: ApiTypes.POST
        },
        function*(body) {
            yield put(actions.hideAddPracticeModal())
            yield put(actions.addPracticeSuccess(body))
        },
        function*() {
            yield put(actions.addPracticeFailure())
            return "Failed to add practice"
        }
    )
}

function* addPracticeSuccess(action) {
    const { practice } = action
    yield put(
        modifyResource({
            name: C.PRACTICE_UNITS,
            dataTransform: data => [...data, practice]
        })
    )
}

function* setPracticeStatus(action) {
    const { practiceUnitId, practiceUnitName, status } = action
    if (status === false) {
        const confirmMessage =
            "Disable practice unit: " + practiceUnitName + "?"
        const confirmed = yield call(confirmSaga, confirmMessage)
        if (!confirmed) {
            return
        }
    }
    yield* tryWithNotifications(
        {
            url: `/api/practice_units/id/${practiceUnitId}/status/${status}`,
            method: ApiTypes.PUT
        },
        function*() {
            yield put(actions.setPracticeStatusSuccess(practiceUnitId, status))
        },
        "Failed to set practice status"
    )
}

function* setPracticeStatusSuccess(action) {
    const { practiceUnitId, status } = action
    yield updatePracticeInList(practiceUnitId, {
        status: status ? C.ACTIVE : C.INACTIVE
    })
}

function* updatePractice(action) {
    const practiceUnitId = yield select(
        state => state[C.ADMIN_NAME].selectedPractice.id
    )

    yield* tryWithNotifications(
        {
            url: `/api/practice_units/${practiceUnitId}`,
            body: {
                ...action.payload,
                tags: getTagsForRequest(action.payload[C.PRACTICE_FIELDS.TAGS])
            },
            method: ApiTypes.PUT
        },
        function*(body) {
            yield put(actions.updatePracticeSuccess(practiceUnitId, body))
            yield put(actions.hideEditPracticeModal())
        },
        function*() {
            yield put(actions.updatePracticeFailure())
            return "Failed to update practice"
        }
    )
}

function* updatePracticeSuccess(action) {
    const { practiceUnitId, payload } = action
    yield* updatePracticeInList(practiceUnitId, payload)
}

function* updatePracticeInList(practiceUnitId, newPractice) {
    yield put(
        modifyResource({
            name: C.PRACTICE_UNITS,
            dataTransform: data => {
                const index = data.findIndex(
                    p => p.practiceUnitId === practiceUnitId
                )
                const newData = [...data]
                newData[index] = {
                    ...data[index],
                    ...newPractice
                }
                return newData
            }
        })
    )
}

function* getUserBody(request = {}, isNew) {
    const userPrimaryPractices = yield select(primaryPracticeIdsSelector)
    const getAccessLevel = yield select(getAccessLevelSelector)

    const body = _.omit(request, [
        ...EXTRA_PROCESSING_FIELDS,
        ...NON_EXTERNAL_FIELDS
    ]) // not strictly necessary to do this, but neater

    // some fields need extra processing
    const [tagMap, password, notHandlers = []] = _.at(
        request,
        EXTRA_PROCESSING_FIELDS
    )
    const tags = getTagsForRequest(tagMap)

    // external users aren't allowed to have practices or providers
    const accessLevel = getAccessLevel(request)
    const isExternal = accessLevel === C.EXTERNAL_VALUE
    const nonExternals = _.mapValues(
        _.pick(request, NON_EXTERNAL_FIELDS),
        value => (isExternal ? [] : value)
    )

    // can't get providers whose primary practice you can't access
    // which can happen if you add a practice, add a provider in that practice, then remove the practice
    nonExternals[C.USER_FIELDS.PROVIDERS] = _.filter(
        nonExternals[C.USER_FIELDS.PROVIDERS],
        pUserId =>
            nonExternals[C.USER_FIELDS.USER_UNITS].includes(
                userPrimaryPractices[pUserId]
            )
    )

    const defaultHandlers = yield request[C.USER_FIELDS.IS_DEFAULT] && isNew // only care about default handlers on new users
        ? select(
              userIdsInPracticeSelector(request[C.USER_FIELDS.PRIMARY_PRACTICE])
          )
        : []
    const handlers = _.without(
        _.union(request[C.USER_FIELDS.HANDLERS], defaultHandlers),
        ...notHandlers
    )

    return {
        ...body,
        ...nonExternals,
        [C.USER_FIELDS.HANDLERS]: handlers,
        tags,
        password // it might look like we didn't process this at all, but actually we changed the field's name from "newPassword" to "password"
    }
}

function* addUser(action) {
    yield* tryWithNotifications(
        {
            url: `/api/users`,
            body: yield* getUserBody(action.data, true),
            method: ApiTypes.POST
        },
        function*() {
            yield put(actions.addUserSuccess())
            // XXX might be faster to just receive the rows for the new user and add them to C.USERS with a modifyResource()... but that's potentially finicky if we want to do the same thing for updateUser
            yield put(actions.hideAddUserModal())
        },
        function*() {
            yield put(actions.addUserFailure())
            return "Failed to add user"
        }
    )
}

function* updateUser(action) {
    const userId = yield select(state => state[C.ADMIN_NAME].selectedUserId)

    yield* tryWithNotifications(
        {
            url: `/api/users/${userId}`,
            body: yield* getUserBody(action.data),
            method: ApiTypes.PUT
        },
        function*() {
            yield put(actions.updateUserSuccess())
            yield put(actions.hideEditUserModal())
        },
        function*() {
            yield put(actions.updateUserFailure())
            return "Failed to update user"
        }
    )
}

export function* confirmSaga(confirmMessage) {
    yield put(actions.showConfirmModal(confirmMessage))

    const { yes } = yield race({
        yes: take(actionTypes.CONFIRM_YES),
        no: take(actionTypes.CONFIRM_NO)
    })

    yield put(actions.hideConfirmModal())

    return !!yes
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(actionTypes.SET_PRACTICE_STATUS, setPracticeStatus)
        }),
        call(function*() {
            yield takeEvery(
                actionTypes.SET_PRACTICE_STATUS_SUCCESS,
                setPracticeStatusSuccess
            )
        }),
        call(function*() {
            yield takeEvery(actionTypes.UPDATE_PRACTICE, updatePractice)
        }),
        call(function*() {
            yield takeEvery(
                actionTypes.UPDATE_PRACTICE_SUCCESS,
                updatePracticeSuccess
            )
        }),
        call(function*() {
            yield takeEvery(actionTypes.ADD_PRACTICE, addPractice)
        }),
        call(function*() {
            yield takeEvery(
                actionTypes.ADD_PRACTICE_SUCCESS,
                addPracticeSuccess
            )
        }),
        call(function*() {
            yield takeEvery(actionTypes.ADD_USER, addUser)
        }),
        call(function*() {
            yield takeEvery(
                actionTypes.HIDE_EDIT_PRACTICE_MODAL,
                hideEditPracticeModal
            )
        }),
        call(function*() {
            yield takeEvery(actionTypes.HIDE_EDIT_USER_MODAL, hideEditUserModal)
        }),
        call(function*() {
            yield takeEvery(actionTypes.HIDE_ADD_USER_MODAL, hideAddUserModal)
        }),
        call(function*() {
            yield takeEvery(
                actionTypes.HIDE_ADD_PRACTICE_MODAL,
                hideAddPracticeModal
            )
        }),
        call(function*() {
            yield takeEvery(actionTypes.UPDATE_USER, updateUser)
        })
    ])
}
