import React from "react"
import moment from "moment"
import { Field } from "redux-form"
import { Col, Row } from "react-bootstrap"
import { LabelledDatePicker, SelectField } from "../../core/CustomFields"
import { MONTH_STRING } from "../../dates"
import { POPULATION_OPTIONS } from "../../constants"
import * as DC from "../../dashboard_filters/constants"
import * as C from "../constants"
import * as QC from "../constants"

const dateValidator = value => {
    if (!value) {
        return undefined
    }

    const startDate = moment()
        .subtract(2, "years")
        .startOf("year")
    const endDate = moment().startOf("month")
    const currentDate = moment(value, MONTH_STRING).startOf("month")
    const isValid =
        currentDate.isSameOrAfter(startDate) &&
        currentDate.isSameOrBefore(endDate)

    return isValid ? undefined : "Must be within last 3 calendar years"
}

export const QSRFilters = ({
    formValues = {},
    options: {
        measures,
        measureLibraries,
        payers,
        providers,
        units,
        productClasses,
        productGroups,
        incentivePrograms
    }
}) => (
    <Row>
        <Col sm={6} lg={4}>
            <Field
                name={C.FILTERS.AS_OF_DATE}
                component={LabelledDatePicker}
                dateFormat={MONTH_STRING}
                validDates={dateValidator}
                validate={[dateValidator]}
                initialViewMode="months"
                disabled={formValues[DC.FILTERS.ID]} // as it'll be ignored in a favorite
            />
        </Col>
        <Col sm={6} lg={4}>
            <Field
                name={C.FILTERS.POPULATION}
                component={SelectField}
                options={POPULATION_OPTIONS}
                notClearable
                required
            />
        </Col>
        <Col sm={6} lg={4}>
            <Field
                name={C.FILTERS.INCENTIVE_PROGRAMS}
                component={SelectField}
                options={incentivePrograms}
                multi
            />
        </Col>
        <Col sm={6} lg={4}>
            <Field
                name={C.FILTERS.PAYERS}
                component={SelectField}
                options={payers}
                multi
            />
        </Col>
        <Col sm={6} lg={4}>
            <Field
                name={C.FILTERS.PRODUCTS}
                component={SelectField}
                options={productClasses}
                multi
            />
        </Col>
        <Col sm={6} lg={4}>
            <Field
                name={QC.FILTERS.PRODUCT_GROUPS}
                component={SelectField}
                options={productGroups}
                multi
            />
        </Col>
        <Col sm={12} lg={8}>
            <Field
                name={C.FILTERS.UNITS}
                component={SelectField}
                options={units}
                multi
            />
        </Col>
        <Col sm={6} lg={4}>
            <Field
                name={C.FILTERS.PROVIDERS}
                component={SelectField}
                options={providers}
                multi
            />
        </Col>
        <Col sm={6} lg={4}>
            <Field
                name={C.FILTERS.MEASURE_LIBRARIES}
                component={SelectField}
                options={measureLibraries}
                multi
            />
        </Col>
        <Col lg={8}>
            <Field
                name={C.FILTERS.MEASURES}
                component={SelectField}
                options={measures}
                multi
            />
        </Col>
    </Row>
)
