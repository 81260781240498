// noinspection JSUnresolvedVariable

import classNames from "classnames"
import React from "react"
import _ from "lodash"
import { Hoverlay } from "../core/Hoverlay"
import { getTagSourceText } from "../user_tags/helpers"
import { timestampFormat } from "../dates"
import { YesNoIndicator } from "../indicators"
import * as PC from "../permission_managment/constants"
import * as C from "./constants"
import {
    isDisabled,
    isUserAdminInPractice,
    isUserInPractice,
    isVirtualPractice
} from "./helpers"
import {
    getUserName,
    getUserNameWithLoginId,
    isSuperuser
} from "./components/users/helpers"
import { InputBox } from "./components/users/providers/ProviderInputs"

// TODO replace these react-table columns with RV

const COLUMN_LEFT = {
    headerClassName: "text-left",
    className: "text-left"
}
const COLUMN_CENTER = {
    headerClassName: "text-center",
    className: "text-center"
}

const getPracticeCellClassName = row =>
    classNames("practice-cell", {
        disabled: isDisabled(row),
        virtual: isVirtualPractice(row)
    })

const PracticeActions = ({ row }) => (
    <div style={{ textAlign: "center" }}>
        <button
            className="icon-button"
            title={isDisabled(row) ? "Activate" : "Disable"}
            onClick={() =>
                row.setPracticeStatus(
                    row[C.PRACTICE_TABLE_KEYS.PRACTICE_ID],
                    row[C.PRACTICE_TABLE_KEYS.PRACTICE_NAME],
                    isDisabled(row)
                )
            }
        >
            <i
                className={classNames(
                    "fa",
                    isDisabled(row) ? "fa-check" : "fa-trash-o"
                )}
            />
        </button>
        <button
            className="icon-button"
            title="Edit"
            onClick={() =>
                row.showEditPracticeModal(
                    _.pick(row, [
                        C.PRACTICE_TABLE_KEYS.PRACTICE_NAME,
                        C.PRACTICE_TABLE_KEYS.PRACTICE_ID,
                        C.PRACTICE_TABLE_KEYS.REGION_ID
                    ])
                )
            }
        >
            <i className="fa fa-pencil" />
        </button>
    </div>
)

const UserStatusCell = ({ original }) =>
    C.USER_STATUS[original[C.USER_TABLE_KEYS.STATUS]] || null

const DisabledCell = ({ children, disabled, title, className, ...props }) => (
    <span
        {...props}
        title={disabled ? title : undefined}
        className={classNames(className, { disabled })}
    >
        {children}
    </span>
)

const ProviderNameCell = (selectedUser, practiceId) => ({
    original,
    value
}) => {
    const primaryPractice =
        original[C.USER_TABLE_KEYS.PRACTICES]?.find(
            practice => practice.isPrimaryPractice
        ) ?? {}

    const isObserver =
        selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE] === practiceId &&
        primaryPractice[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID] !== practiceId
    const observerIcon = isObserver ? (
        <Hoverlay
            tooltip={`Observer from ${
                primaryPractice[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_NAME]
            }`}
        >
            <span className="fa fa-user-circle-o" />
        </Hoverlay>
    ) : null

    return (
        <DisabledCell title="Inactive user" disabled={isDisabled(original)}>
            {value} {observerIcon}
        </DisabledCell>
    )
}

export const PRACTICE_COLUMNS = [
    {
        ...COLUMN_LEFT,
        Header: "Practice Unit",
        accessor: C.PRACTICE_TABLE_KEYS.PRACTICE_NAME,
        minWidth: 150,
        Cell: ({ original, value }) => (
            <span className={getPracticeCellClassName(original)}>
                {!isVirtualPractice(original)
                    ? value
                    : original.roles.includes(C.ROLE_SUPERUSER)
                    ? "Users without practices"
                    : "Users from other practices"}
            </span>
        )
    },
    {
        ...COLUMN_LEFT,
        Header: "Practice Tags",
        accessor: C.PRACTICE_TABLE_KEYS.REGION_NAME,
        Cell: ({ value, original }) => (
            <span className={getPracticeCellClassName(original)}>
                {(value || []).join("; ")}
            </span>
        )
    },
    {
        ...COLUMN_CENTER,
        Header: "Status",
        accessor: C.PRACTICE_TABLE_KEYS.STATUS,
        Cell: ({ original }) => (
            <span className={getPracticeCellClassName(original)}>
                {isVirtualPractice(original)
                    ? ""
                    : UserStatusCell({ original })}
            </span>
        ),
        width: 60
    },
    {
        ...COLUMN_CENTER,
        Header: "Action",
        Cell: ({ original }) =>
            _.includes(original.roles, C.ROLE_SUPERUSER) &&
            !isVirtualPractice(original) && <PracticeActions row={original} />,
        sortable: false,
        width: 80
    }
]

export const USER_COLUMNS = [
    { expander: true },
    {
        ...COLUMN_LEFT,
        Header: "First Name",
        accessor: C.USER_TABLE_KEYS.FIRST_NAME
    },
    {
        ...COLUMN_LEFT,
        Header: "Last Name",
        accessor: C.USER_TABLE_KEYS.LAST_NAME
    },
    {
        ...COLUMN_CENTER,
        Header: "User Name",
        accessor: C.USER_TABLE_KEYS.LOGIN_ID,
        width: 100
    },
    {
        ...COLUMN_CENTER,
        Header: "Role",
        accessor: C.USER_TABLE_KEYS.USER_ROLE,
        width: 110
    },
    {
        ...COLUMN_LEFT,
        Header: "Primary Practice",
        accessor: C.USER_TABLE_KEYS.PRIMARY_PRACTICE,
        Cell: ({ value, original }) => (
            <div
                className={classNames(
                    original[C.USER_TABLE_KEYS.PRIMARY_PRACTICE_ACTIVE] ||
                        "practice-inactive"
                )}
            >
                {value}
            </div>
        )
    },
    {
        ...COLUMN_CENTER,
        Header: "Status",
        accessor: C.USER_TABLE_KEYS.STATUS,
        Cell: UserStatusCell,
        width: 60
    },
    {
        ...COLUMN_CENTER,
        Header: (
            <Hoverlay tooltip="NPI present in organization's provider&nbsp;roster">
                Licensed
            </Hoverlay>
        ),
        accessor: C.USER_TABLE_KEYS.LICENSED,
        Cell: ({ value }) => <YesNoIndicator value={value} />,
        width: 70
    },
    {
        ...COLUMN_LEFT,
        Header: "Last Login",
        accessor: C.USER_TABLE_KEYS.LAST_LOGIN,
        Cell: ({ original }) =>
            timestampFormat(original[C.USER_TABLE_KEYS.LAST_LOGIN]),
        width: 150
    },
    {
        ...COLUMN_CENTER,
        Header: "Attempts",
        accessor: C.USER_TABLE_KEYS.ATTEMPTS,
        width: 70
    },
    {
        ...COLUMN_CENTER,
        Header: "Action",
        Cell: ({ original }) => (
            <div style={{ textAlign: "center" }}>
                <button
                    className="icon-button"
                    onClick={() =>
                        original.showEditUserModal(
                            original[C.USER_TABLE_KEYS.USER_ID]
                        )
                    }
                >
                    <span className="fa fa-pencil" />
                </button>
            </div>
        ),
        sortable: false,
        width: 60
    }
]

export const USER_LIST_PRACTICE_COLUMNS = [
    {
        ...COLUMN_LEFT,
        accessor: C.USER_PRACTICE_KEYS.PRACTICE_NAME,
        Header: "Practices",
        Cell: ({ original, value }) => (
            <DisabledCell
                title="Inactive practice"
                disabled={isDisabled(original)}
            >
                {value}
            </DisabledCell>
        )
    },
    {
        ...COLUMN_LEFT,
        accessor: C.USER_PRACTICE_KEYS.ROLE_IN_PRACTICE,
        Header: "Role",
        width: 110,
        Cell: ({ original, value }) => (
            <DisabledCell
                title="Inactive practice"
                disabled={isDisabled(original)}
            >
                {value}
            </DisabledCell>
        )
    },
    {
        ...COLUMN_CENTER,
        accessor: C.USER_PRACTICE_KEYS.IS_PRIMARY_PRACTICE,
        Header: "Primary",
        Cell: ({ original }) => (
            <DisabledCell
                title="Inactive practice"
                disabled={isDisabled(original)}
                className={classNames(
                    "text-center",
                    original[C.USER_PRACTICE_KEYS.IS_PRIMARY_PRACTICE] &&
                        "fa fa-check"
                )}
            />
        ),
        width: 75
    }
]

export const USER_LIST_TAG_COLUMNS = [
    { ...COLUMN_LEFT, accessor: C.TAG_COLUMNS.TAG_NAME, Header: "Tags" },
    {
        ...COLUMN_LEFT,
        accessor: C.TAG_COLUMNS.SOURCE,
        Header: "Source",
        width: 150,
        Cell: ({ original }) => {
            const source = getTagSourceText(original)
            return (
                <Hoverlay tooltip={source.tooltip} disabled={!source.tooltip}>
                    {source.label || "Unknown"}
                </Hoverlay>
            )
        }
    }
]

export const EDIT_USER_PRACTICE_COLUMNS = [
    {
        ...COLUMN_LEFT,
        Header: "Practice Unit",
        accessor: C.EDIT_USER_PRACTICE_KEYS.PRACTICE_NAME,
        Cell: ({ original, value }) => (
            <DisabledCell title={original.lock} disabled={original.lock}>
                {value}
            </DisabledCell>
        )
    },
    {
        ...COLUMN_CENTER,
        Header: ({ data }) => {
            const firstLineData = (!_.isEmpty(data) && data[0]._original) || {}
            return firstLineData.editingSuperuser ? "Member" : "Visible"
        },
        id: C.EDIT_USER_PRACTICE_KEYS.VISIBLE,
        accessor: row =>
            _.includes(
                row.originalUserUnits,
                row[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID]
            ), // basing accessor on originalUserUnits so that sort order doesn't change when a value is checked or unchecked
        Cell: ({ original }) => (
            <input
                type="checkbox"
                onChange={() =>
                    original.toggleVisible(
                        original[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID]
                    )
                }
                checked={isUserInPractice(original)}
                title={original.lock}
                disabled={original.lock}
            />
        ),
        width: 70
    },
    {
        ...COLUMN_CENTER,
        Header: "Primary",
        id: C.EDIT_USER_PRACTICE_KEYS.PRIMARY,
        accessor: ({ practiceUnitId, originalPrimaryPractice }) =>
            practiceUnitId === originalPrimaryPractice,
        Cell: ({ original }) => (
            <input
                type="radio"
                onChange={() => {
                    original.setPrimary(
                        original[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID]
                    )
                }}
                checked={
                    original[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID] ===
                    original[C.USER_TABLE_KEYS.PRIMARY_PRACTICE]
                }
                title={original.lock || original.lockPrimaryPractice}
                disabled={original.lock || original.lockPrimaryPractice}
            />
        ),
        width: 70
    },
    {
        ...COLUMN_CENTER,
        Header: "Admin",
        id: C.EDIT_USER_PRACTICE_KEYS.ADMIN,
        accessor: row =>
            _.includes(
                row.originalAdminUnits,
                row[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID]
            ),
        Cell: ({ original }) => (
            <input
                type="checkbox"
                onChange={() =>
                    original.toggleAdmin(
                        original[C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID]
                    )
                }
                checked={
                    original.editingSuperuser || isUserAdminInPractice(original)
                }
                title={original.lock || original.lockAdmin}
                disabled={original.lock || original.lockAdmin}
            />
        ),
        width: 70
    }
]

// need this bit in case the selected user's npi status changes while editing, since the row in providers won't update.
const hasNpi = row =>
    !!(row.selectedUserId === row[C.USER_TABLE_KEYS.USER_ID]
        ? row.selectedUser[C.USER_FIELDS.NPI]
        : row[C.USER_TABLE_KEYS.NPI])

const getCanAccessFlags = row => ({
    isSelf: row.selectedUserId === row[C.USER_TABLE_KEYS.USER_ID],
    isPracticeAdmin: row.isPracticeAdmin,
    isSuperuser: isSuperuser(row)
})

const isHandler = (selectedUser, selectedUserId, userId) => {
    const isSelected = _.includes(selectedUser[C.USER_FIELDS.HANDLERS], userId)
    const isDeselected = _.includes(
        selectedUser[C.USER_FIELDS.NOT_HANDLERS],
        userId
    )
    const isNewUser = selectedUserId === C.NEW_USER_ID
    const selectByDefault = selectedUser[C.USER_FIELDS.IS_DEFAULT]

    return isSelected || (isNewUser && selectByDefault && !isDeselected)
} // only use default handlers on a new user

export const USER_PROVIDER_COLUMNS = (
    selectedUser,
    canManageUser,
    selectedUserId,
    practiceUnitId
) => [
    {
        ...COLUMN_LEFT,
        Header: "User",
        headerClassName: "text-left providers-th",
        id: "name",
        accessor: getUserNameWithLoginId,
        Cell: ProviderNameCell(selectedUser, practiceUnitId)
    },
    {
        ...COLUMN_CENTER,
        Header: `${selectedUser[C.USER_FIELDS.LOGIN_ID]} can see panel`,
        headerClassName: "text-center providers-th-sm",
        id: "accessible",
        accessor: row => hasNpi(row) * 10 + !getCanAccessFlags(row).isSelf,
        Cell: ({ original }) => (
            <InputBox
                name={getUserName(selectedUser)}
                checked={_.includes(
                    selectedUser[C.USER_FIELDS.PROVIDERS],
                    original[C.USER_TABLE_KEYS.USER_ID]
                )}
                lock={original.providerLock}
                onChange={original.toggleProvider(
                    original[C.USER_TABLE_KEYS.USER_ID]
                )}
            />
        ),
        width: 80
    },
    {
        ...COLUMN_CENTER,
        show: canManageUser,
        Header: `Can see ${selectedUser[C.USER_FIELDS.LOGIN_ID]}'s panel`,
        headerClassName: "text-center providers-th-sm",
        id: "canAccess",
        accessor: row => {
            const { isSelf, isPracticeAdmin, isSuperuser } = getCanAccessFlags(
                row
            )
            return !isSuperuser * 100 + !isPracticeAdmin * 10 + !isSelf // sort anyone who can always see the panel at the end
        },
        Cell: ({ original }) => (
            <InputBox
                name={getUserName(original)}
                checked={isHandler(
                    selectedUser,
                    selectedUserId,
                    original[C.USER_TABLE_KEYS.USER_ID]
                )}
                lock={original.handlerLock}
                onChange={original.toggleHandler(
                    original[C.USER_TABLE_KEYS.USER_ID]
                )}
            />
        ),
        width: 90
    }
]

export const USER_TAG_COLUMNS = [
    {
        ...COLUMN_LEFT,
        Header: "Tag",
        id: C.TAG_COLUMNS.TAG,
        accessor: tag => tag[C.TAG_COLUMNS.TAG_NAME],
        Cell: ({ original }) => (
            <span
                style={{
                    fontWeight: original[C.TAG_COLUMNS.READ] ? "bold" : "normal"
                }}
            >
                {original[C.TAG_COLUMNS.TAG_NAME]}
            </span>
        )
    },
    {
        ...COLUMN_CENTER,
        id: C.TAG_COLUMNS.READ,
        Header: "Can Access",
        Cell: ({ original }) => (
            <Hoverlay
                disabled={!original.readLock}
                tooltip={original.readLock}
                forceWrapper
            >
                {/*adding this wrapper because disabled inputs don't dispatch events, but the wrapper can. */}
                <input
                    type="checkbox"
                    checked={original[C.TAG_COLUMNS.READ]}
                    disabled={!!original.readLock}
                    onChange={() =>
                        original.setTagAccess(
                            original[C.TAG_COLUMNS.TAG_ID],
                            PC.ACCESS_PRIVILEGES.READ
                        )
                    }
                />
            </Hoverlay>
        ),
        sortable: false,
        width: 90
    },
    {
        ...COLUMN_CENTER,
        id: C.TAG_COLUMNS.EDIT,
        Header: "Can Edit",
        Cell: ({ original }) => (
            <Hoverlay
                disabled={!original.editLock}
                tooltip={original.editLock}
                forceWrapper
            >
                <input
                    type="checkbox"
                    checked={original[C.TAG_COLUMNS.EDIT]}
                    disabled={!!original.editLock}
                    onChange={() =>
                        original.setTagAccess(
                            original[C.TAG_COLUMNS.TAG_ID],
                            PC.ACCESS_PRIVILEGES.EDIT
                        )
                    }
                />
            </Hoverlay>
        ),
        sortable: false,
        width: 90
    }
]
