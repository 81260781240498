import _ from "lodash"
import { getFormSyncErrors } from "redux-form"
import { createSelector, createStructuredSelector } from "reselect"
import { getResourceData, getResourceDataAsArray } from "../core/fetcher"
import { EMPTY_STRING, intersectionExists, itemsToObject } from "../utils"
import { easyFormValueSelector } from "../selectorUtils"
import { FILTER_OPTIONS } from "../constants"
import { isDisabled } from "./helpers"
import {
    capPrivilege,
    editableTagsSelector,
    userTagsSelector
} from "./tagSelectors"
import {
    getAccessLevelSelector,
    isExternalAccess,
    isSuperuserAccess
} from "./helperSelectors"
import * as C from "./constants"
import {
    getPrimaryPractice,
    getProviderLocks,
    getUserById,
    practiceIsValid
} from "./components/users/helpers"
import { USER_TABLE_KEYS } from "./constants"

// UTILITY FUNCTIONS

const getUserPrimaryPractice = user =>
    _.find(user?.[C.USER_TABLE_KEYS.PRACTICES], {
        [C.USER_PRACTICE_KEYS.IS_PRIMARY_PRACTICE]: true
    })

// SELECTORS

// practices to display in the Add/Edit User modal
export const userModalPracticesSelector = createSelector(
    getResourceDataAsArray(C.PRACTICE_UNITS),
    getResourceDataAsArray(C.USERS, data => data.users),
    state => state[C.ADMIN_NAME].selectedUserId,
    state => state.authentication,
    (myPractices, users, userId, { userNumericId: myId, roles }) => {
        /* first, check if you have access to the user's primary practice */
        const user = getUserById(users, userId)
        const primaryPractice = getPrimaryPractice(user)
        const canAccessPrimaryPractice =
            _.isEmpty(primaryPractice) ||
            _.some(myPractices, {
                [C.PRACTICE_TABLE_KEYS.PRACTICE_ID]:
                    primaryPractice[C.USER_PRACTICE_KEYS.PRACTICE_ID]
            })

        /* now get the practices you can admin */
        const nonZeroPractices = _.filter(
            myPractices,
            C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID
        ) // superusers have access to the 0 practice, because that's where external users are displayed. But we don't want to see them here.

        const myActivePractices = nonZeroPractices.map(practice => ({
            ..._.pick(practice, [
                C.PRACTICE_TABLE_KEYS.PRACTICE_ID,
                C.PRACTICE_TABLE_KEYS.PRACTICE_NAME,
                C.PRACTICE_TABLE_KEYS.STATUS
            ]),
            lockPrimaryPractice: canAccessPrimaryPractice
                ? undefined
                : "You do not have access to this user's primary practice, so you cannot change it.",
            lock: isDisabled(practice)
                ? "This practice is inactive."
                : userId === myId &&
                  roles.includes(C.ROLE_PRACTICE_ADMIN) &&
                  !roles.includes(C.ROLE_SUPERUSER)
                ? "Practice admins cannot edit their own practices."
                : undefined
        }))

        /* also need to include disabled rows for any other practices the user
         * might have, even if you can't access them, so you can see what the
         * user's primary practice is. */

        const otherUserPractices = _.filter(
            user[C.USER_TABLE_KEYS.PRACTICES],
            C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID
        ) // again, don't want to see the 0 practice
            .map(practice => ({
                ..._.pick(practice, [
                    C.EDIT_USER_PRACTICE_KEYS.PRACTICE_ID,
                    C.EDIT_USER_PRACTICE_KEYS.PRACTICE_NAME,
                    C.EDIT_USER_PRACTICE_KEYS.PRACTICE_STATUS
                ]),
                lock: isDisabled(practice)
                    ? "This practice is inactive."
                    : "You do not have access to this practice."
            }))

        return _.uniqBy(
            [...myActivePractices, ...otherUserPractices],
            C.PRACTICE_TABLE_KEYS.PRACTICE_ID
        )
    }
)

// name of current selected user's primary practice
export const primaryPracticeNameSelector = formName =>
    createSelector(
        state => state[C.ADMIN_NAME],
        easyFormValueSelector(formName, ...Object.values(C.USER_FIELDS)),
        getResourceData(FILTER_OPTIONS, data => data.units),
        getResourceData(C.USERS, data => data.users),
        ({ selectedUserId }, formValues = {}, myPractices = [], users) => {
            if (!selectedUserId) {
                // no selected user, so don't worry about it
                return EMPTY_STRING
            }

            const primaryPracticeId = formValues[C.USER_FIELDS.PRIMARY_PRACTICE]

            // if their primary practice is in your filter options, we'll use that
            const foPrimaryPractice = _.find(myPractices, {
                value: primaryPracticeId
            })
            if (!_.isEmpty(foPrimaryPractice)) {
                return foPrimaryPractice.label
            }

            if (selectedUserId === C.NEW_USER_ID) {
                // ah: they're a new user, and they haven't been assigned practices yet
                return EMPTY_STRING
            }

            // otherwise, take from their "practices" field because they might have practices you don't
            const user = getUserById(users, selectedUserId)
            return (
                getPrimaryPractice(user, C.USER_PRACTICE_KEYS.PRACTICE_NAME) ??
                EMPTY_STRING
            )
        }
    )

// current selected user, with extra fields
export const selectedUserSelector = formName =>
    createSelector(
        easyFormValueSelector(formName, ...Object.values(C.USER_FIELDS)),
        getAccessLevelSelector,
        primaryPracticeNameSelector(formName),
        (formValues = {}, getAccessLevel, primaryPracticeName) => ({
            ...formValues,
            [C.USER_FIELDS.PRIMARY_PRACTICE_NAME]: primaryPracticeName,
            [C.USER_FIELDS.IS_SUPERUSER]: isSuperuserAccess(
                getAccessLevel(formValues)
            ),
            [C.USER_FIELDS.IS_EXTERNAL]: isExternalAccess(
                getAccessLevel(formValues)
            )
        })
    )

export const activePracticesSelector = createSelector(
    getResourceData(C.PRACTICE_UNITS),
    practices =>
        _.sortBy(_.filter(practices, practiceIsValid), practice =>
            practice[C.PRACTICE_TABLE_KEYS.PRACTICE_NAME].toLowerCase()
        )
)

export const panelPracticesSelector = form =>
    createSelector(
        selectedUserSelector(form),
        activePracticesSelector,
        (selectedUser, practices) =>
            _.filter(practices, practice =>
                _.includes(
                    selectedUser.userUnits,
                    practice[C.PRACTICE_TABLE_KEYS.PRACTICE_ID]
                )
            )
    )

const shouldShowUserPanel = (
    selectedUserId,
    practiceIds,
    selectedUserPPId
) => user => {
    if (user[C.USER_TABLE_KEYS.USER_ID] === selectedUserId) {
        // this user is the selected user
        return false
    }

    const userPrimaryPractice = getUserPrimaryPractice(user)
    if (
        practiceIds.includes(
            userPrimaryPractice?.[C.USER_PRACTICE_KEYS.PRACTICE_ID]
        )
    ) {
        // the selected user is in this user's primary practice
        return true
    }

    // return whether this user is in the selected user's primary practice
    return _.some(user[C.USER_TABLE_KEYS.PRACTICES], {
        [C.USER_PRACTICE_KEYS.PRACTICE_ID]: selectedUserPPId
    })
}

const defaultPracticeProvidersSelector = createSelector(
    getResourceDataAsArray(C.USERS, data => data.users),

    users => {
        const primaryPractices = itemsToObject(
            users,
            C.USER_TABLE_KEYS.USER_ID,
            getUserPrimaryPractice
        )
        return _(primaryPractices)
            .pickBy(C.USER_PRACTICE_KEYS.IS_DEFAULT)
            .mapValues(C.USER_PRACTICE_KEYS.PRACTICE_ID)
            .invertBy()
            .mapValues(ids => ids.map(Number))
            .value()
    }
)

const myAdminPracticeIdsSelector = createSelector(
    userModalPracticesSelector,
    myPractices =>
        _.filter(myPractices, practice => !practice.lock).map(
            practice => practice[C.USER_PRACTICE_KEYS.PRACTICE_ID]
        )
)

export const panelUsersSelector = form =>
    createSelector(
        selectedUserSelector(form),
        state => state[C.ADMIN_NAME].selectedUserId,
        getResourceData(C.USERS, data => data.users),
        panelPracticesSelector(form),
        myAdminPracticeIdsSelector,
        (
            selectedUser,
            selectedUserId,
            users,
            practices,
            myAdminPracticeIds
        ) => {
            const practiceIds = _.map(
                practices,
                C.PRACTICE_TABLE_KEYS.PRACTICE_ID
            )
            const selectedUserPrimaryPracticeId =
                selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE]

            // which users should we show
            const filteredUsers = _.filter(
                users,
                shouldShowUserPanel(
                    selectedUserId,
                    practiceIds,
                    selectedUserPrimaryPracticeId
                )
            )

            // which practices should we show them in
            const practiceUsers = _.groupBy(filteredUsers, user => {
                const primaryPracticeId = getUserPrimaryPractice(user)?.[
                    C.USER_PRACTICE_KEYS.PRACTICE_ID
                ]
                return practiceIds.includes(primaryPracticeId)
                    ? primaryPracticeId
                    : selectedUserPrimaryPracticeId
            })

            // add locks to each user
            return _.mapValues(practiceUsers, (users, practiceId) =>
                users.map(user => ({
                    ...user,
                    ...getProviderLocks(
                        user,
                        Number(practiceId),
                        selectedUser,
                        Number(selectedUserId),
                        myAdminPracticeIds
                    )
                }))
            )
        }
    )

// can the current user edit this user's info?
export const canEditUserInfoSelector = createSelector(
    selectedUserSelector(C.EDIT_USER_FORM),
    userModalPracticesSelector,
    state => state[C.ADMIN_NAME].selectedUserId,
    state => state.authentication.userId,
    (selectedUser, practices, selectedUserId, myId) => {
        if (selectedUserId === myId) {
            // of course you can edit yourself!
            return true
        }

        const primaryPractice =
            _.find(practices, {
                [C.USER_PRACTICE_KEYS.PRACTICE_ID]:
                    selectedUser[C.USER_TABLE_KEYS.PRIMARY_PRACTICE]
            }) || {}
        return !primaryPractice.disabled
    }
)

export const invalidTabsSelector = form =>
    createSelector(getFormSyncErrors(form), errors => {
        const errorFields = _.keys(errors)
        const invalidTabs = _.pickBy(C.USER_TAB_FIELDS, fieldList =>
            intersectionExists(fieldList, errorFields)
        )
        return _.keys(invalidTabs).map(Number)
    })

export const userModalConnectSelector = form =>
    createStructuredSelector({
        selectedUser: selectedUserSelector(form),
        practices: userModalPracticesSelector,
        tags: userTagsSelector(form),
        invalidTabs: invalidTabsSelector(form),
        isSuperUser: state =>
            state.authentication.roles?.includes(C.ROLE_SUPERUSER) // whether you, the active user, are a superuser
    })

// initial values for AddUserModal

export const addUserInitialValuesSelector = createSelector(
    panelUsersSelector(C.ADD_USER_FORM),
    easyFormValueSelector(C.ADD_USER_FORM, C.USER_FIELDS.PRIMARY_PRACTICE),
    easyFormValueSelector(C.ADD_USER_FORM, C.USER_FIELDS.IS_DEFAULT),
    defaultPracticeProvidersSelector,
    (panelUsers, primaryPractice, isDefault, defaultProviders) => {
        return {
            [C.USER_FIELDS.USER_STATUS]: C.ACTIVE,
            [C.USER_FIELDS.ROLE]: 0,
            [C.USER_FIELDS.HANDLERS]: [],
            [C.USER_FIELDS.NOT_HANDLERS]: [],
            [C.USER_FIELDS.PROVIDERS]: defaultProviders[primaryPractice]
        }
    }
)

// initial values for the EditUserModal

export const editUserInitialValuesSelector = createSelector(
    getResourceData(C.SELECTED_USER),
    editableTagsSelector,
    getAccessLevelSelector,
    userModalPracticesSelector,
    (selectedUser = {}, editableTags = [], getAccessLevel, practices) => {
        // special handling for tags
        const selectedUserTags = selectedUser[C.USER_FIELDS.TAGS] || []
        const editableTagIds = new Set(
            _.map(editableTags, C.TAG_COLUMNS.TAG_ID)
        )

        const manualTags = selectedUserTags
            .filter(
                tag =>
                    tag[C.TAG_COLUMNS.SOURCE] === C.TAG_SOURCES.MANUAL &&
                    editableTagIds.has(tag[C.TAG_COLUMNS.TAG_ID])
            )
            .map(tag => ({
                ...tag,
                [C.TAG_COLUMNS.PRIVILEGE]: capPrivilege(
                    tag[C.TAG_COLUMNS.PRIVILEGE],
                    getAccessLevel(selectedUser)
                )
            }))
        const tags = itemsToObject(
            manualTags,
            C.TAG_COLUMNS.TAG_ID,
            C.TAG_COLUMNS.PRIVILEGE
        )

        // limit practices to ones the active user can see

        const practiceIds = _.map(practices, C.USER_PRACTICE_KEYS.PRACTICE_ID)
        const userUnits = _.intersection(
            selectedUser[C.USER_FIELDS.USER_UNITS],
            practiceIds
        )
        const adminUnits = _.intersection(
            selectedUser[C.USER_FIELDS.ADMIN_UNITS],
            practiceIds
        )

        // the rest of the values
        return {
            ..._.mapValues(
                selectedUser,
                (value, key) => value ?? C.initialValues[key]
            ),
            [C.USER_FIELDS.TAGS]: tags,
            [C.USER_FIELDS.USER_UNITS]: userUnits,
            [C.USER_FIELDS.ADMIN_UNITS]: adminUnits
        }
    }
)

// and for the EditPracticeModal

export const practiceInitialValuesSelector = createSelector(
    state => state[C.ADMIN_NAME].selectedPractice,
    getResourceData(C.PRACTICE_TAGS),
    (selectedPractice = {}, practiceTags = {}) => ({
        [C.PRACTICE_FIELDS.PRACTICE_NAME]: selectedPractice.name,
        [C.PRACTICE_FIELDS.REGION_ID]: selectedPractice.regionId,
        [C.PRACTICE_FIELDS.TAGS]: itemsToObject(
            practiceTags[selectedPractice.id],
            C.TAG_COLUMNS.TAG_ID,
            C.TAG_COLUMNS.PRIVILEGE
        )
    })
)

export const userIdsInPracticeSelector = practiceId =>
    createSelector(panelUsersSelector(C.ADD_USER_FORM), panelUsersMap =>
        _.map(panelUsersMap[practiceId], C.USER_TABLE_KEYS.USER_ID)
    )
